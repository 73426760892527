<template>
    <div>
        <div class="hero">
            <img src="./../assets/Bilder/Lisa/IMG_5183_lang_1.jpg" alt="Webdesiger und Webentwicklerin Hero">
        </div>
        <section class="welcome" id="home">
            <h2> Hi, ich bin Lisa. </h2>
            <h2 class="h2"> Eine einzigartige, leidenschaftliche Frontendentwicklerin, die Dein 
            <br> Team bald perfekt ergänzt? — Finde es heraus! </h2>
            <div class="scroll-down"></div> 
        </section>
        <section class="start">
            <h3 class="skyblue"> Coding & Design vereint in einer Person</h3>
            <span> <b>Mein Ziel ist es, Dein Team und Deine Projekte mit all meinen Fähigkeiten bestmöglich zu unterstützen. </b> 
                Egal ob Vue, React oder Angular. <b>Ich liebe jedes dieser JavaScript Frameworks</b> und habe bereits mit 
                jedem dieser Frameworks an Projekten gearbeitet.
                In diesen Projekten habe ich mit Datenbanken wie MySQL oder PostgreSQL gearbeitet, 
                mit Postman und vor allem mit REST APIs. 
                Mit node.js konnte ich bereits Erfahrungen im Backend machen, doch ...
                <br> <br> 
                <b>... mein Herz schlägt fürs Frontend.</b>
                </span>
            <div class="horizontal-divider"></div>
        </section>
        <section class="strength">
            <div class="phones"> 
                <div class="phone-content">
                    <div class="phone basic">
                        <div>
                            <div class="screen-top">
                                <div class="speakers"></div>
                                <div class="camera"></div>
                            </div>
                            <h5 class="headline"> Coding </h5>
                            <div class="content">
                                Ich liebe es zu Coden, da sich hier speziell im Bereich Frontend Sprache, Logik und Design vermischen.
                                <br> <br>
                                Es fasziniert mich immer wieder, wenn Konzepte visuell und funktional sichtbar und erlebbar werden 
                                - allein durch Code.
                                <br> <br>
                            </div>
                        </div>
                        <div class="topline"></div>
                    </div>
                    <div class="phone basic">
                        <div>
                            <div class="screen-top">
                                <div class="speakers"></div>
                                <div class="camera"></div>
                            </div>
                            <h5 class="headline"> Design </h5>
                            <div class="content">
                                Ich liebe es neue Oberflächen 
                                zu entwerfen und gestalten, mit Farben und Schriften zu arbeiten und eine geile visuelle Experience zu schaffen
                                <br> <br>
                                Design darf nicht nur einfach schön aussehen, sondern funktional sein!
                                Die User sollen sich wohlfühlen und geleitet werden.
                            </div>
                        </div>
                        <div class="topline"></div>
                    </div>
                    <div class="phone basic">
                        <div>
                            <div class="screen-top">
                                <div class="speakers"></div>
                                <div class="camera"></div>
                            </div>
                            <h5 class="headline"> Team </h5>
                            <div class="content">
                                Selbstständiges Arbeiten liegt mir, doch genauso gut kann ich mich in ein Team eingliedern.
                                <br> <br>
                                Ich bin offen für Kritik und bringe Ideen ein, wenn es gewünscht wird.
                                <br> <br>
                                
                            </div>
                        </div>
                        <div class="topline"></div>
                    </div>
                </div>
            </div>
        </section>
        <div class="hero hero-cite">
            <div class="hero-cite-desktop"> 
                <h2> "Lisa ergriff von sich aus die Initiative und setzte sich stets mit guter Einsatzbereitschaft
                für uns ein."  </h2>         
            </div>
            <img src="./../assets/Bilder/More/DSC00340-1-2.jpg" alt="Hintergrundbild Zitat">
            <div class="img-overlay"></div>
        </div>
        <section class="superpower" id="superpower">
            <h2> Meine Stärken </h2>
            <span> Um Dich voranzubringen, gebe ich mein Bestes und <b>komme motiviert und begeistert zur Arbeit.</b> 
                Ich bin bereit, mich <b>in Themen einzuarbeiten</b> und immer wieder Neues zu lernen.
                Bei mir investierst Du in eine <b>junge, loyale Mitarbeiterin.</b> 
                <br> 
                Was mich auszeichnet, ist meine <b>Kreativität, logisches und komplexes Denken, 
                    technisches Verständnis und eine sehr hohe Flexibilität.</b> </span>
            <div class="keys-wrapper">
                <div class="keys">
                    <div class="circle authenticity-circle"> </div>
                    <div class="circle design-circle"> </div>
                    <div class="circle technology-circle"> </div>
                </div>
                <div class="circle-borders">
                    <div class="circle authenticity-border"> </div>
                    <div class="circle design-border"> </div>
                    <div class="circle technology-border"> </div>
                </div>
                <div class="keys-text-wrapper">
                    <div class="keys-text authenticity"> 
                        <h4 class="authenticity-text"> Creativity </h4> 
                        <p>	Kreatives und logisches
                        <br> Denken | Finden von  
                        <br> passenden Lösungen </p>
                    </div>
                    <div class="keys-text design"> 
                        <h4 class="design-text"> Complexity </h4> 
                        <p>	schnelle Auffassungsgabe
                        <br> und verstehen komplexer
                        <br> Zusammenhänge</p>
                    </div>
                    <div class="keys-text technology"> 
                        <h4 class="technology-text">  Flexibility </h4> 
                        <p> Hohe Teamfähigkeit
                        <br> Offen für Ideen und
                        <br> Kritik von anderen</p>
                    </div>
                </div>
            </div>
            <div class="keys-wrapper-mobile">
                    <div class="key">
                        <div class="circles">
                            <div class="circle authenticity-circle"> </div>
                            <div class="circle authenticity-border"> </div>
                        </div>
                        <div class="keys-text authenticity"> 
                           <h4 class="authenticity-text"> Creativity </h4> 
                            <p>	Kreatives und logisches
                            <br> Denken | Finden von  
                            <br> passenden Lösungen </p>
                        </div>
                    </div>
                    <div class="key">
                        <div class="circles">
                            <div class="circle design-circle"> </div>
                            <div class="circle design-border"> </div>
                        </div>
                        <div class="keys-text design"> 
                            <h4 class="design-text"> Complexity </h4> 
                            <p>	schnelle Auffassungsgabe
                            <br> und verstehen komplexer
                            <br> Zusammenhänge</p>
                        </div>
                    </div>
                    <div class="key">
                        <div class="circles">
                            <div class="circle technology-circle"> </div>
                            <div class="circle technology-border"> </div>
                        </div>
                        <div class="keys-text technology"> 
                            <h4 class="technology-text">  Flexibility </h4> 
                            <p> Hohe Teamfähigkeit
                            <br> Offen für Ideen und
                            <br> Kritik von anderen</p>
                        </div>
                    </div>
                </div>
        </section>
        <section id="skills">
            <h3 class="skyblue"> Hier kann ich Dich unterstützen </h3>
            <a @click="renderCloud()" class="cloud-btn-wrapper"> 
                <button @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                Klicke jetzt hier auf den Button! </button>
            </a>
            <div class="word-cloud-wrapper">
                <vue-word-cloud
                    :key="render"
                    class="word-cloud"
                    :words="words"
                    :color="([, weight]) => getColor(weight)"
                    :font-size-ratio="5"
                    :font-weight="([, weight]) => getWeight(weight)"
                    font-family="Raleway"
                    rotation-unit="deg"
                    :rotation="([, weight]) => getRotation()"
                    :spacing="0.6"
                    :animation-easing="'ease-out'"
                    :animation-overlap="0"
                    :animation-duration="1500"
                    v-bind:style="{ opacity: opacityCloud }"
                />
            </div>
            <div class="horizontal-divider"></div>
        </section>
        <div class="hero hero-cite cite-second">
            <div class="hero-cite-desktop"> 
                <h2>
                    "Hervorzuheben ist ihre gut entwickelte Fähigkeit,
                    konzeptionell und konstruktiv zu arbeiten, sowie ihre präzise Urteilsfähigkeit."
                </h2>
            </div>
            <img src="./../assets/Bilder/More/DSC00409-1.jpg" alt="Hintergrundbild Zitat">
            <div class="img-overlay"></div>
        </div>
        <section class="start projekte" id="projekte">
            <h2 class="headline"> Ausgewählte Projekte </h2>
            <div class="projekt iot"> 
                <h3 class="headline-iot"> IoT-Leitstelle </h3>
                <h4> <span class="subheadline-ba"> BACHELORARBEIT </span> <span class="subheadline-iot"> | ENTWICKLUNG EINES  
                    <br> INDIVIDUALISIERBAREN DASHBOARDS ZUR ÜBERWACHUNG VON 
                    <br> IOT-DATEN AUS DER ENERGIEVERSORGUNGSTECHNIK </span> </h4>
                <div class="horizontal-divider"></div>
                <div> In meiner Bachelorarbeit habe ich in Kooperation mit 
                    <a class="inline-link" href="https://www.smart-city-solutions.de/">Smart City Solutions GmbH</a>   
                    und <a class="inline-link" href="https://em-wasseranlagenbau.de/home/">E&M Wasseranlagenbau GmbH</a>  
                    eine IoT-Plattform konzipiert und implementiert, 
                    die die Überwachung Daten von Wasseranlagen vereinfacht. </div>
                <div class="btn">
                <a href="2020_Lisa_Miller_BA_IoT-Leitstelle.pdf" target="_blank"> 
                    <button @mouseenter="cursorEnter" @mouseleave="cursorOut" href="2020_Lisa_Miller_BA_IoT-Leitstelle.pdf"> 
                    Bachelorarbeit herunterladen</button>
                </a>
                </div>
            </div>
            <div class="projekt"> 
                <h3> Bergliebe App </h3>
                <div> Ich liebe es in den Bergen wandern und Bergsteigen zu gehen.
                    Schon mit ein paar Monaten hat mich mein Papa in die Berge mitgenommen. 
                    Heute habe diese Leidenschaft auf einer 
                    <router-link class="inline-link" to="/berge" target="_blank"> interaktiven Weltkarte </router-link>
                    visualisiert und jedes Jahr kommen weitere Gipfel dazu.
                </div>
                <div class="btn">
                <router-link class="btn" to="/berge" target="_blank">
                    <button @mouseenter="cursorEnter" @mouseleave="cursorOut" href="2020_Lisa_Miller_BA_IoT-Leitstelle.pdf"> 
                    Entdecke die App</button>
                </router-link>
                </div>
            </div>
        </section>
        <div class="hero hero-cite cite-third">
            <div class="hero-cite-desktop"> 
                <h2>
                    "Frau Miller arbeitete mit Ihren Kollegen
                    ausgezeichnet im Team zusammen. Sie besitzt ein umfassendes Wissen, welches das eigene
                    Fachgebiet überschreitet."
                </h2>
            </div>
            <img src="./../assets/Bilder/More/Lisa-02.jpg" alt="Hintergrundbild Zitat">
            <div class="img-overlay"></div>
        </div>
        <section class="book-now" id="kontakt">
            <h2> Kontaktiere mich </h2>
            <p class="desktop"> Das Leben ist zu kurz, dass wir uns nicht begegnen!
                <br> Ich freu mich sehr, Dich und das Unternehmen kennenzulernen. </p>
            <div class="button-wrapper">
                <a href="mailto:lisa@millerlisa.com"> 
                    <button @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                    Schreibe mir eine E-Mail </button>
                </a>
                <a href="tel:+491635138890"> 
                    <button @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                    Ruf mich an </button>
                </a>
            </div>
            <p class="kontaktformular-aufruf" > Oder benutze einfach das Formular hier. </p>
            <div v-if="sended" class="button-wrapper form-sended form-container">
                <p class="danke"> Dankeschön. </p>
                <p class="error"> Deine Nachricht wurde erfolgreich zugestellt und ich antworte Dir so schnell wie möglich. </p>
                <button @mouseenter="cursorEnter" @mouseleave="cursorOut" @click="sendAgain"> 
                    Erneut eine Nachricht senden</button>
            </div>
            <div v-if="error" class="button-wrapper form-sended form-container form-error">
                <p class="error"> Ups. <br> Leider ging etwas schief. </p>
                <button @mouseenter="cursorEnter" @mouseleave="cursorOut" @click="sendAgain"> 
                    Erneut versuchen</button>
            </div>
            <form>
                <div v-if="!sended && !error" class="form-container">
                    <label>Dein Name <b>*</b></label>
                    <input @mouseenter="cursorEnterInput" @mouseleave="cursorOutInput"
                        type="text" 
                        v-model="name"
                        name="name"
                    >
                    <label>Deine Firma</label>
                    <input @mouseenter="cursorEnterInput" @mouseleave="cursorOutInput"
                        type="text" 
                        v-model="company"
                        name="name"
                    >
                    <label>Deine E-Mail <b>*</b> </label>
                    <input @mouseenter="cursorEnterInput" @mouseleave="cursorOutInput"
                        id="mailformular"
                        type="email" 
                        v-model="email"
                        name="email"
                        :onchange="mailValidation()"
                        >
                    <label>Deine Nachricht an mich <b>*</b></label>
                    <textarea @mouseenter="cursorEnterInput" @mouseleave="cursorOutInput"
                        name="message"
                        v-model="message"
                        cols="30" rows="5">
                    </textarea>
                </div>
                <div v-if="name == '' || email == '' || message == ''" class="send-mail"> 
                    <img src="../assets/Icons/icon-send.png" alt="Sending Icon"> 
                    <div> Senden </div>
                </div>
                <div v-else-if="isSending" class="send-mail"> 
                    <img src="../assets/Icons/icon-send.png" alt="Sending Icon"> 
                    <div> wird gesendet ... </div>
                </div>
                <div v-else @mouseenter="cursorEnter" @mouseleave="cursorOut" class="send-mail send-mail-ok"> 
                    <img src="../assets/Icons/icon-send.png" alt="Sending Icon"> 
                    <div @click="sendEmail"> Senden </div>
                </div>
            </form>
        </section>
    </div>
</template>

<script>
import Projects from '@/components/Projects.vue';
import VueWordCloud from "vuewordcloud";
import axios from "axios";

export default {
    name: 'Websites',
    components: {
        [VueWordCloud.name]: VueWordCloud,
    },
    data() {
        return {
            currentNumber: 0,
            rendered: false,
            render: 0,
            opacityCloud: 0,
            words: [
                ["Vue", 20],
                ["Frontend", 22],
                ["PWA", 18],
                ["REST", 22],
                ["CSS", 12],
                ["Postman", 12],
                ["Sass", 12],
                ["Wordpress", 5],
                ["MySQL", 3],
                ["PostgreSQL", 3],
                ["Java Android", 12],
                ["Gestaltung", 12],
                ["Leaflet", 12],
                ["React", 16],
                ["Git", 16],
                ["jQuery", 6],
                ["node.js", 6],
                ["VS Code", 10],
                ["Leaflet", 7],
                ["InDesign", 8],
                ["Photoshop", 7],
                ["Lightroom", 6],
                ["Illustrator", 6],
                ["Axure", 5],
                ["Bootstrap", 5],
                ["Adobe XD", 5],
                ["HTML", 9],
                ["Coding", 26],
                ["Angular", 24],
                ["Fotografie", 8],
                ["Design", 20],
                ["User Interace", 10],
                ["JavaScript", 2],
                ["JavaScript", 3],
                ["Vue", 4],
                ["CSS", 5],
                ["Angular", 4],
                ["HTML", 2],
                ["Coding", 5],
                ["React", 3],
                ["jQuery", 2],
                ["VS Code", 3],
                ["Java Android", 4],
            ],
            name: '',
            company: '',
            email: '',
            message: '',
            sended: false,
            error: false,
            isSending: false,
        }
    },
    mounted() {
        window.addEventListener("scroll", this.scrollUp);
        this.scrollUp();
    },
    computed: {
        
    },
    methods: {
        cursorEnter() {
            this.$store.commit("setMouseOverLink", true);
        },
        cursorOut(){
            this.$store.commit("setMouseOverLink", false);
        },
        next() {
            this.currentNumber += 1
        },
        prev() {
            this.currentNumber -= 1
        },
        currentReference(tag) {
            return this.references[Math.abs(this.currentNumber) % this.references.length][tag];
        },
        getColor(weight) {
            if (weight == 24 || weight == 26 || weight == 16) {
                return "#ffffff";
            }
            const h = Math.floor(Math.random() * 30 + 200);
            const s = Math.floor(Math.random() * 15 + 60);
            const l = Math.floor(Math.random() * 55 + 30);
            return "hsl(" + h + ", " + s + "%, " + l + "%)";
        },
        getRotation() {
            const number = Math.floor(Math.random() * 180);
            if (number < 90) {
                return 0;
            } else {
                return 90;
            }
            },
            getWeight(weight) {
            if (weight == 26) {
                return "900";
            } else if (weight == 24) {
                return "400";
            }
            const number = Math.floor(Math.random() * 1000);
            return number;
        },
        renderCloud() {
            if (!this.$store.state.wordCloud) {
                this.$store.commit("setWordCloud", 1);
                this.opacityCloud = 1;
            }
            this.render++;
        },
        scrollUp() {
            const scrollTop =
                window.pageYOffset ||
                (document.documentElement || document.body.parentNode || document.body)
                .scrollTop;
                const startWrapper = document.getElementById("start-fotos-scroll");
                if (startWrapper != undefined) {
                    let translate = (-500 + scrollTop*0.1);
                    startWrapper.style.transform = `translateX(${translate}px)`;
                }
        },
        cursorEnterInput() {
            document.getElementById("cursor").style.opacity = 0;
        },
        cursorOutInput(){
            document.getElementById("cursor").style.opacity = 1;
        },
        mailValidation() {
            if (document.getElementById("mailformular")) {
                if (this.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                    document.getElementById("mailformular").style.borderBottomColor = '#7AA3C8';
                } else {
                    document.getElementById("mailformular").style.borderBottomColor = '#CA085E';
                }
            }
        },
        sendEmail() {
            this.isSending = true;
            let data = {
                    "user_id": 'user_RjwBCA41NBR3K5T0diyP7',
                    "service_id": 'lisa_business',
                    "template_id": 'neue_nachricht',
                    "template_params": {
                        'name': this.name,
                        'company': this.company,
                        'email': this.email,
                        'message': this.message
                    }
                };
            axios.post('https://api.emailjs.com/api/v1.0/email/send', 
                data
            ).then(response => {
                if (response.status == 200) {
                    this.isSending = false;
                    this.sended = true;
                    this.company = '';
                    this.name = '';
                    this.email = '';
                    this.message = '';
                    this.cursorOut();
                } else {
                    this.isSending = false;
                    this.error = true;
                    this.cursorOut();
                }
            });
            this.cursorOut();
            
        },
        sendAgain() {
            this.sended = false;
            this.error = false;
            this.cursorOut();
        }
    }
}
</script>

<style lang="scss" scoped>
$pink: #CA085E;
$lightblue: #C4D1DB;
$midblue: #7AA3C8;
$skyblue: #4476AE;
$darkblue: #031940;
$blackblue: #010819;
$grey: #131313;

b {
    font-weight: 500;
}
.hero {
    width: calc(100vw - 40px);
    height: 60vh;
    margin: 0 20px 20px 20px;
    overflow: hidden;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }
}
.welcome {
    text-align: center;
    margin-top: -8.5rem;
    h2 {
        margin: 0 30px;
        margin-bottom: 5.5rem;
    }
    .h2 {
        font-weight: 200;
        font-size: 1.6rem;
    }
    .scroll-down {
        position: relative;
        top: 5rem;
        left: calc(50% - 3px);
        width: 6px;
        height: 20px;
        background-color: $skyblue;
        border-radius: 10px;
        animation: mouseAni 3s infinite;
    }
}

.start {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    margin-top: 6.5rem;
    margin-bottom: 50px;
}

.horizontal-divider {
    width: 200px;
    border-bottom: 1px solid white;
    margin: 30px auto;
}

.strength {
    .phone {
        width: 250px;
    }
}

.superpower {
    text-align: center;
    max-width: 650px;
    margin: 0 auto;
    padding-top: 7.5rem;
    padding-bottom: 150px;
    .keys-wrapper {
        position: relative;
        .circle {
            width: 180px;
            height: 180px;
            border-radius: 50%;
        }
    }
    .keys-wrapper-mobile {
        display: none;
    }
    .keys {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 4rem auto;
        .authenticity-circle {
            position: relative;
            left: 30px;
            background-color: $skyblue;
            opacity: 0.15;
        }
        .design-circle {
            background-color: $midblue;
            z-index: 4;	
            opacity: 0.15;
        }
        .technology-circle {
            position: relative;
            left: -30px;
            background-color: $skyblue;
            opacity: 0.15;
        }
    }
    .circle-borders {
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        .authenticity-border {
            position: relative;
            left: 33px;
            border: 2px solid $skyblue;
        }
        .design-border {
            border: 2px solid $midblue;
        }
        .technology-border {
            position: relative;
            left: -33px;
            border: 2px solid $skyblue;
        }
    }
    .keys-text-wrapper {
        position: absolute;
        top: 0;
        left: -10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        .keys-text {
            text-align: center;
            min-width: 100px;
        }
        .authenticity {
            position: relative;
            left: -50px;
        }
        .technology {
            position: relative;
            left: 50px;
        }
        h4 {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            height: 180px;
        }
        .authenticity-text {
            color: $midblue;
        }
        .design-text {
            position: relative;
            left: -5px;
            color: $skyblue;
        }
        .technology-text {
            color: $midblue;
        }
        p {
            margin-top: 40px;
            opacity: 1;
        }
    }
}

.hero-cite {
    height: 60vh;
    margin-top: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    .hero-cite-desktop {
        display: flex;
    }
    img {
        position: relative;
        z-index: -1;
    }
    div {
        text-align: center;
        height: 100%;
        position: absolute;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        h2 {
            max-width: 1000px;
            margin: 0;
            padding: 0 40px;
        }
    }
    .img-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 0;
        background-color: $grey;
        opacity: 0.8;
    }
}

.services-wrapper {
    margin: 10rem 0;
    text-align: center;
    p {
        max-width: 700px;
        margin: 4rem auto 2rem auto;
    }
}

#skills {
  padding: 70px 0 30px 0;
  margin: 20px;
  transition: all 0.1s ease;
  box-sizing: border-box;
  text-align: center;
  background-color: $blackblue;
  .word-cloud-wrapper {
    width: 80vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: all 1s ease-out;
    margin: 50px 10vw 50px 10vw;
    padding: 40px;
    box-sizing: border-box;
  }
  .cloud-btn-wrapper {
    display: flex;
    justify-content: center;
    button {
        background-color: white;
    }
  }
}

.cite-second {
    height: 60vh;
    margin-top: 0;
    margin-bottom: 0;
    .hero-cite-desktop {
        display: flex;
    }
    h2 {
        max-width: 1200px !important;
        margin: 0;
    }
    img {
        object-position: 100% 55%;
    }
}

.projekte {
    margin: 0 auto;
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
    .projekt {
        border: 1px solid $skyblue;
        padding: 40px;
        margin: 20px 0;
    }
    .iot {
        margin-top: 50px;
    }
    .headline {
        color: white !important;
    }
    h3 {
        color: $skyblue;
    }
    .headline-iot {
        margin-bottom: 10px;
    }
    h4 span {
        font-weight: 900 !important;
    }
    .subheadline-iot {
        color: #7AA3C8;
        opacity: 0.4;
    }
    .btn {
        margin-top: 30px !important;
    }
    .inline-link {
        font-weight: 400;
        text-decoration: underline !important;
    }
}

.cite-third {
    margin-top: 0;
}

.book-now {
    margin: 0 20px 20px 20px;
    padding: 6rem 0;
    background-color: $lightblue;
    color: $darkblue;
    text-align: center;
    .mobile {
        display: none;
    }
    p {
        max-width: 520px;
        margin: 0 auto;
        font-weight: 300;
    }
    h2 {
        color: white;
        margin-bottom: 2rem;
    }
    .button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 3rem auto;
        button {
            color: white;
            background-color: $darkblue;
            margin: 0 1rem;
        }
        button:hover {
            background-color: $skyblue;
        }
    }

    .form-sended {
        box-sizing: border-box;
        background-color: #4476AE1f;
        border-radius: 15px;
        border: 2px solid $midblue;
        display: flex;
        width: 600px;
        flex-direction: column;
        padding: 4rem 30px;
        margin: 1rem auto;
        .danke {
            font-family: "Bacalisties";
            font-size: 2rem;
            margin-bottom: 2rem;
        }
        .error {
            margin-bottom: 2rem;
        }
    }
    .form-error {
        background-color: #ca085f38;
        border: 2px solid $pink;
    }
    form {
        width: 600px;
        text-align: left;
        margin: 1rem auto;
        .form-container {
            box-sizing: border-box;
            background-color: #4476AE1f;
            border-radius: 15px;
            border: 2px solid $midblue;
            display: flex;
            flex-direction: column;
            padding: 20px 30px;
            width: 600px;
            label {
                margin-top: 10px;
                cursor: none;
            }
            input, textarea {
                margin-bottom: 10px;
                background-color: transparent;
                border: none;
                border-bottom: 2px solid $midblue;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            }
            input {
                height: 1.1rem;
            }
            input:focus, textarea:focus {
                outline: none;
            }
        }   
    }
    .send-mail {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 600px;
        margin: 0 auto;
        margin-top: 1.5rem;
        opacity: 0.4;
        img {
            width: 25px;
            margin-right: 10px;
        }
        div {
            font-family: 'Raleway', sans-serif;
            background-color: transparent;
            border: none;
            cursor: none;
            color: $blackblue;
            font-size: 1.2rem;
            font-weight: 400;
            margin-right: 10px;
        }
    }
    .send-mail-ok {
        opacity: 1;
    }
}

footer {
    padding: 20px;
    display: flex;
    justify-content: center;
    .link {
        margin: 0 10px;
    }
}

@keyframes mouseAni {
    0% {
        -webkit-transform: rotate(180deg) translate(0, 120px);
        -moz-transform: rotate(180deg) translate(0, 120px);
        -ms-transform: rotate(180deg) translate(0, 120px);
        -o-transform: rotate(180deg) translate(0, 120px);
        transform: rotate(180deg) translate(0, 120px);
        opacity: 0;
    }
    5% {
        -webkit-transform: rotate(180deg) translate(0, 120px);
        -moz-transform: rotate(180deg) translate(0, 120px);
        -ms-transform: rotate(180deg) translate(0, 120px);
        -o-transform: rotate(180deg) translate(0, 120px);
        transform: rotate(180deg) translate(0, 120px);
        opacity: 0;
    }
    85% {
        opacity: 1;
    }
    95% {
        -webkit-transform: rotate(180deg) translate(0, 80px);
        -moz-transform: rotate(180deg) translate(0, 80px);
        -ms-transform: rotate(180deg) translate(0, 80px);
        -o-transform: rotate(180deg) translate(0, 80px);
        transform: rotate(180deg) translate(0, 80px);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(180deg) translate(0, 120px);
        -moz-transform: rotate(180deg) translate(0, 120px);
        -ms-transform: rotate(180deg) translate(0, 120px);
        -o-transform: rotate(180deg) translate(0, 120px);
        transform: rotate(180deg) translate(0, 120px);
        opacity: 0;
    }
}

@media screen and (max-width: 1300px) {
    .start {
        margin-top: 12rem
    }

    .hero {
        width: 100vw;
        height: 50vh;
        margin: 0;
    }
    .welcome {
        margin-top: -7em;
        h2 {
            margin-bottom: 6.5rem;
        }
        .h2 {
            font-size: 1.5rem;
            margin: 0 auto;
        }
        .scroll-down {
            top: 12rem;
        }
    }

    .superpower {
        padding-bottom: 30px;
        .keys-wrapper {
            display: none;
        }
        .keys-wrapper-mobile {
            display: block;
            margin-top: 4rem;
            .key {
                margin-top: 2rem;
                position: relative;
                position: relative;
                display: flex;
                flex-direction: column;
                .circles {
                    position: relative;
                    margin: 0 auto;
                }
                .circle {
                    width: 180px;
                    height: 180px;
                    border-radius: 50%;
                    margin: 0 auto;
                    position: absolute;
                    top: 0px;
                    left: -90px;
                }
                .authenticity-circle {
                    background-color: $skyblue;
                    opacity: 0.15;
                }
                .authenticity-border {
                    border: 2px solid $skyblue;
                }
                .design-circle {
                    background-color: $midblue;
                    z-index: 4;	
                    opacity: 0.15;
                }
                .technology-circle {
                    background-color: $skyblue;
                    opacity: 0.15;
                }
                .design-border {
                    border: 2px solid $midblue;
                }
                .technology-border {
                    border: 2px solid $skyblue;
                }
                .keys-text {
                    position: relative;
                    z-index: 5;
                    margin-top: 50px;
                    background-image: linear-gradient(transparent, $grey, $grey);
                    
                    .authenticity-text {
                        color: $midblue;
                    }
                    .design-text {
                        color: $skyblue;
                    }
                    .technology-text {
                        color: $midblue;
                    }
                        p {
                        margin-top: 20px;
                    }
                }
            } 
        }
    }

    .hero-cite {
        margin-top: 5rem;
        h2 {
            padding: 0 30px;
            font-size: 30px;
        }
    }

    .cite-third {
        margin-top: 0rem;
    }

    .services-wrapper {
        margin: 11rem 0;
        p {
            max-width: 500px;
            margin: 4rem auto 3rem auto;
        }
    }

    .cite-second {
        margin-top: 0;
        margin-bottom: 0;
        height: 70vh;
        h2 {
            max-width: 500px;
            margin: 0 auto;
        }
    }
    .book-now {
        margin: 0;
        padding: 5rem 0;
        p {
            max-width: 500px;
        }
        .horizontal-divider {
            width: 200px;
            border-bottom: 1px solid $darkblue;
            margin: 30px auto 20px auto;
        }
        .button-wrapper {
            margin: 5rem auto 5rem auto;
        }
    }
}


@media screen and (max-width: 700px) {
    .welcome {
        text-align: left;
        margin-top: -5em;
        h2 {
            margin-bottom: 3.5rem;
            margin-left: 20px;
        }
        .h2 {
            font-size: 1.2rem;
            max-width: 400px;
            padding: 0;
            margin: 0 20px;
        }
        .scroll-down {
            top: 10rem;
        }
    }
    .start {
        margin-top: 10rem;
        text-align: left;
        padding: 0 20px;
        margin-bottom: 2rem;
        h3 {
            max-width: 400px;
            margin-left: 0;
            margin-right: 0;
            line-height: 130%;
        }
        .horizontal-divider {
            width: 150px;
            border-bottom: 1px solid white;
        }
        .keys-wrapper {
            display: none;
        }
        .keys-wrapper-mobile {
            text-align: center;
        }
    }

    .hero-cite {
        height: 70vh;
        margin-top: 5rem;
        .hero-cite-desktop {
            h2 {
                padding: 0 30px;
                font-size: 30px;
            }
        }
        img {
            object-position: 42% 100%;
        }
        .img-overlay {
            opacity: 0.7;
        }
    }

    .superpower {
        padding: 6rem 20px 3rem 20px;
        text-align: left;
        .keys-wrapper-mobile {
            text-align: center;
        }
    }

    #skills {
        margin: 0;
        padding: 60px 20px;
        .word-cloud-wrapper {
            width: 100%;
            height: 50vh;
            margin: 60px 0;
            padding: 0;
        }
    }

    .services-wrapper {
        margin: 9rem 0;
        p {
            max-width: 100%;
            text-align: left;
            padding: 0 20px;
        }
    }

    .references-wrapper {
        margin: 0;
        padding: 5rem 0;
        .references {
            margin-top: 2rem;
            margin-left: 0;
            margin-right: 0;
            .reference {
                padding: 1rem;
                margin-bottom: 0;
            }
        }
    }

    .cite-second {
        margin: 0;
        height: 70vh;
        img {
            object-position: 40% 100%;
            transform: scale(1.4);
        }
    }

    .cite-third {
        margin: 0;
        height: 80vh;
        img {
            object-position: 40% 100%;
            transform: scale(1.2);
        }
    }

    .projekte {
        margin: 0;
        padding: 6rem 20px;
        text-align: left;
    }

    .book-now {
        margin: 0;
        padding: 5rem 20px;
        text-align: left;
        p {
           max-width: 100%;
        }
        .horizontal-divider {
            width: 200px;
            border-bottom: 1px solid $darkblue;
            margin: 30px auto 20px auto;
        }
        .button-wrapper {
            margin: 20px auto 40px auto;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: start;
            button {
                margin: 10px 0;
            }
        }
        .kontaktformular-aufruf {
            font-size: 16px;
            font-weight: 800;
            text-align: center;
        }
        form, .form-container, .send-mail {
            width: 100% !important;
            margin-top: 25px;
            border: none !important;
        }
        .form-container {
            padding: 20px 15px !important;
        }
    }
}
</style>