<template>
    <div class="shortcut-contact">  
        <a href="tel:+491635138890">  
            <button id="tel-button" @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
             +49 1635138890 
            </button>
        </a>
        <a href="mailto:lisa@millerlisa.com">
            <button id="mail-button" @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
             lisa@millerlisa.com 
            </button>
        </a>
    </div>
</template>

<script>
export default {
    name: "Contact",
    methods: {
        cursorEnter() {
            this.$store.commit("setMouseOverLink", true);
        },
        cursorOut(){
            this.$store.commit("setMouseOverLink", false);
        }
    }
}
</script>

<style lang="scss" scoped>
$pink: #CA085E;
$lightblue: #C4D1DB;
$midblue: #7AA3C8;
$skyblue: #4476AE;
$darkblue: #031940;
$blackblue: #010819;
$grey: #131313;

.shortcut-contact {
    position: fixed;
    top: 80px;
    right: 0;
    width: 0px;
    overflow: hidden;
    transition: all 0.5s ease;
    z-index: 1001;
}
button {
    position: fixed;
    top: 0;
    right: 0;
    margin-bottom: 1rem;
    border: none;
    font-size: 1rem;
    font-weight: 100px;
    padding: 15px 20px;
    overflow: hidden;
    transition: all 0.5s ease-out;
    box-shadow: 0 0 10px -5px black;
    border-radius: 10px 0 0 10px;
    a {
        color: $blackblue;
        text-align: left;
    }
}
#mail-button {
    top: 80px;
    transform: translateX(200px);
}
#tel-button {
    top: 140px;
    transform: translateX(200px);
    transition-delay: 0.1s;
}

@media (max-width: 700px) {
    .shortcut-contact {
        z-index: 9999;
    }
    #mail-button {
        top: 65px;
        transform: translateX(200px);
    }
    #tel-button {
        top: 123px;
        transform: translateX(200px);
        transition-delay: 0.1s;
    }
}
</style>