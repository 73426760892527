<template>
	<div class="explore">
		<div id="map-wrapper">
			<l-map
				id="map"
				:zoom="zoom"
				:center="[latP, lonP]"
				@update:zoom="zoomUpdate"
				@update:center="centerUpdated"
				>
				<l-tile-layer :url="url" ></l-tile-layer>
				<l-marker 
					v-bind:key="index" 
					v-for="(i, index) in mountains.mountains" 
					@mouseleave="removeActive(i)" 
					@mouseenter="showHeart(i)" 
					@click="showMountain(i)" 
					:lat-lng="[i.lat, i.lon]"
					>
					<l-icon>
					<div class="micon-wrapper">
						<div class="map-single-mountain" :id="i.id" :style="'background-color:' + setColor(i.height)">
						<img class="heart" :id="'heart' + i.id" src="../assets/Bilder/Berge/heart.png" />
						</div>
					</div>
					</l-icon>
				</l-marker>
			</l-map>
			<div id="legend">
				<div class="legend-point-wrapper"> 
					<div class="legend-point" id="legend-0000"></div> 
					<p> unter 2000m </p> 
				</div>
				<div class="legend-point-wrapper"> 
					<div class="legend-point" id="legend-2000"></div> 
					<p> 2000m - 2499m </p> 
				</div>
				<div class="legend-point-wrapper"> 
					<div class="legend-point" id="legend-2500"></div> 
					<p> 2500m - 2999m </p> 
				</div>
				<div class="legend-point-wrapper"> 
					<div class="legend-point" id="legend-3000"></div> 
					<p> 3000m - 3499m </p> 
				</div>
				<div class="legend-point-wrapper"> 
					<div class="legend-point" id="legend-3500"></div> 
					<p> über 3500m </p> 
				</div>
			</div>
		</div>
		<div id="mountain-intro">
			<div class="close" @mouseenter="cursorEnter" @mouseleave="cursorOut" @click="closeSidebar"> x </div>
			<div v-if="m != null" class="single-mountain">
				<h2> {{ m.name }} </h2> 
				<div class="info"> 
					<div>
						<div class="horizontal-divider"></div>
						<p class="height bold"> {{ m.height }}m </p>
					</div>
					<div>
						<div class="horizontal-divider" id="animated-line"></div>
						<div class="place">
							<p v-if="m.group != ''"> <span class="mountain-place"> {{ m.group }} </span>
								<br> <span class="mountain-place"> {{ m.region }} </span>
								<br> <span class="mountain-place"> {{ m.country }} </span>
							</p>
							<p v-else> <span class="mountain-place"> {{ m.region }} </span>
								<br> <span class="mountain-place"> {{ m.country }} </span>
							</p>
						</div>
					</div>
				</div>
				<div v-bind:key="i" v-for="(allM, i) in mountains.mountains">
					<img class="img-mountain" v-if="checkToShow(allM)" :src="getImgUrl(allM)" v-bind:alt="m.photo">
				</div>
				<div id="map-text">
					<p class="description"> 
						<span class="bold"> {{ besteigungen(m.date) }} </span>
						<br> <br> 
						{{ m.description }} 
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet';
import L from 'leaflet';
import MoreVue from './More.vue';

export default {
	name: 'Mountains',
	components: {
		LMap,
		LTileLayer,
		LMarker,
		LIcon
	},
	data() {
		return {
			mobile: false,
		heart: false,
		currentCenter: L.latLng(33, -47),
		currentZoom: 4,
		zoom: 4,
		//zoom: 6,
		ext: 'png',
		attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
		url: "https://stamen-tiles-{s}.a.ssl.fastly.net/terrain-background/{z}/{x}/{y}{r}.png",
		//url: "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}",
		//url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
		//attribution: "&copy; Esri &mdash; Esri, DeLorme, NAVTEQ",
		lonP: -33,
		latP: 35,
		//latP: 47,
		//lonP: 20,
		mountains: {
			"mountains": [
			{
				"id": 1,
				"name": "Hochvogel",
				"height": 2592,
				"group": "Hochvogel- und Rosszahngruppe",
				"region": "Allgäuer Alpen",
				"country": "Deutschland/Österreich",
				"lat": 47.3803776,
				"lon": 10.4345785,
				"date": ["2012", "2015", "2019", "2020"],
				"photo": "hochvogel.jpg",
				"description": "Wahrscheinlich wegen seiner unverkennbaren Form einer Pyramide, seiner Dominanz und seiner Nähe ist er mein Lieblingsberg. Schon 4 1/2 Mal war ich oben. Inzwischen ist ein riesiger Spalt am Gipfel, sodass in naher Zukunft ein Teil des Gipfels mit bis zu 260.000 Kubikmetern abstürzen wird. Der Hochvogel war der erste Berg, den ich auch von anderen Gipfel aus erkennen konnte."
			},
			{
				"id": 2,
				"name": "Großer Krottenkopf",
				"height": 2656,
				"group": "Hornbachkette",
				"region": "Allgäuer Alpen",
				"country": "Österreich",
				"lat": 47.3422448,
				"lon": 10.3799098,
				"date": ["2017"],
				"photo": "krottenkopf.jpg",
				"description": "Weil ich unbedingt hochwollte und es für den Nachmittag Regen vorhergesagt hatte, sind wir extra früh aufgestanden und nur mit einem Müsliriegel und Nüssen los. Das war meine erste Sonnenaufgangstour. Wir waren um 7 Uhr am Gipfel. Es war absolut genial. Der Große Krottenkopf in Tirol ist der höchste Berg in den Allgäuer Alpen."
			},
			{
				"id": 3,
				"name": "Großer Daumen",
				"height": 2280,
				"group": "Daumengruppe",
				"region": "Allgäuer Alpen",
				"country": "Deutschland",
				"lat": 47.4404198,
				"lon": 10.3779175,
				"date": ["2015"],
				"photo": "daumen.jpg",
				"description": "Am Daumen liegt die Schwarzenberghütte. Dort habe ich den besten Kaiserschmarrn in meinem Leben gegessen."
			},
			{
				"id": 4,
				"name": "Holzgauer Wetterspitze",
				"height": 2895,
				"group": "",
				"region": "Lechtaler Alpen",
				"country": "Österreich",
				"lat": 47.2064027,
				"lon": 10.3606892,
				"date": ["2014"],
				"photo": "wetterspitze.jpg",
				"description": "Die Wetterspitze war einer meiner beeindruckensten Gipfelbesteigungen. Nur durch Kletteri kommt man zum Gipfel und auf den lezten Metern kommt man einem Loch im Fels vorbei, durch das man 2000 Meter in die tiefe schauen kann. Es gab bis jetzt keinen anderen Gipfel, an dem ich so weiche Knie hatte und schnell wieder runter wollte. Ein unglaubliches Erlebnis!"
			},
			{
				"id": 5,
				"name": "Hochfeiler",
				"height": 3510,
				"group": "",
				"region": "Zillertaler Alpen",
				"country": "Italien",
				"lat": 46.9730909,
				"lon": 11.7278338,
				"date": ["2019"],
				"photo": "hochfeiler.jpg",
				"description": "Mein erster 3000er und dann gleich zwei Mal. Es ist der höchste Berg der Zillertaler Alpen. Am ersten Tag sind wir von der Hochfeilerhütte ganz normal morgens hochgelaufen, am zweiten Tag dann um kurz vor 5. Wir waren 10 min nach Sonnenaufgang am Gipfel. Es war so eine umglaubliche Aussicht und viele magische Momente."
			},
			{
				"id": 6,
				"name": "Entschenkopf",
				"height": 2043,
				"group": "Daumengruppe",
				"region": "Allgäuer Alpen",
				"country": "Deutschland",
				"lat": 47.436478,
				"lon": 10.3326584,
				"date": ["2019"],
				"photo": "entschenkopf.jpg",
				"description": "Schöner Gipfel mit toller Sicht auf Nebelhorn, den Hindelanger Klettersteig und großer Daumen im Süden (Sieht man im Hintergund des Bildes)."
			},
			{
				"id": 7,
				"name": "Sonnenkopf",
				"height": 1712,
				"group": "Daumengruppe",
				"region": "Allgäuer Alpen",
				"country": "Deutschland",
				"lat": 47.4589026,
				"lon": 10.3312552,
				"date": ["2019"],
				"photo": "sonnenkopf.jpg",
				"description": "An diesem Tag hab ich gleich vier Gipfel auf einem gemacht. Die Sonnenköpfe: Sonnenkopf, Heidelbeerkopf und Schnippenkopf, liegen unmittelbar in einer Reihe und machen die Tour zu einem tollen Panoramaerlebnis. Der vierte Gipfel war dann der Entschenkopf, der südlich des Schnippenkopfes liegt (im Bild links)."
			},
			{
				"id": 8,
				"name": "Krinnenspitze",
				"height": 2000,
				"group": "Vilsalpseeberge",
				"region": "Allgäuer Alpen",
				"country": "Österreich",
				"lat": 47.473889, 
				"lon": 10.585278,
				"date": ["2019"],
				"photo": "krinnenspitze.jpg",
				"description": "Auf der Krinnenspitze war unglaublich viel los, auch unter der Woche. Sie ist einfach zu erreichen und man hat einen fantastischen Blick auf die Rote Flüh und den Gimpel. Auf der Tagestour sind wir von Nesselwängle zur Krinnenspitze und dann auf den Litnisschrofen (Gipfel in der Mitte vom Bild)."
			},
			{
				"id": 9,
				"name": "Litnisschrofen",
				"height": 2068,
				"group": "Vilsalpseeberge",
				"region": "Allgäuer Alpen",
				"country": "Österreich",
				"lat": 47.4684026,
				"lon": 10.5703012,
				"date": ["2019"],
				"photo": "litnisschrofen.jpg",
				"description": " Ein toller Berg mit einer abchließenden netten Kraxelei auf den Gipfelgrat. An dem Tag hatten wir eine super Sicht auf die höheren Allgäuer Alpen, vor allem dem Hochvogel."
			},
			{
				"id": 10,
				"name": "Rote Spitze",
				"height": 2130,
				"group": "Vilsalpseeberge",
				"region": "Allgäuer Alpen",
				"country": "Österreich",
				"lat": 47.443056, 
				"lon": 10.499722,
				"date": ["2018"],
				"photo": "roteSpitze.jpg",
				"description": "Die Rote Spitze ist ein Grashügel auf dem Weg vom Schrecksee zur Landsberger Hütte. Von oben hat man einen tollen Blick auf den Vilsalpsee. Auf dem Bild sieht man in der Mitte den Hochvogel. Aufgenommen hab ich es am Schrecksee (Jubiläumsweg), auf dem Weg zur Landsberger Hütte."
			},
			{
				"id": 11,
				"name": "Rubihorn",
				"height": 1957,
				"group": "Daumengruppe",
				"region": "Allgäuer Alpen",
				"country": "Deutschland",
				"lat": 47.424444, 
				"lon": 10.315278,
				"date": ["2018"],
				"photo": "rubihorn.jpg",
				"description": "Die Tagestour auf das Rubihorn ist eine mittelschwere Wanderung. Sie führt am unteren Gaisalpsee vorbei und der Anstieg ist zwar steil, dafür aber kurz."
			},
			{
				"id": 12,
				"name": "Rauhorn",
				"height": 2240,
				"group": "Vilsalpseeberge",
				"region": "Allgäuer Alpen",
				"country": "Deutschland/Österreich",
				"lat": 47.458186, 
				"lon": 10.468567,
				"date": ["2015"],
				"photo": "rauhorn.jpg",
				"description": "Die Rauhornüberschreitung ist eine der anspruchvollsten in den niedrigeren Bergen der Allgäuer Alpen. Der Berg macht dem Namen alle Ehre, er ist ausgesetzt und zerklüftet. Leider war an dem Tag keine so gute Aussicht, aber die Kletterhei zum Gipfel hin hat sehr viel Spaß gemacht!"
			},
			{
				"id": 13,
				"name": "Kreuzspitze",
				"height": 2367,
				"group": "Hochvogel- und Rosszahngruppe",
				"region": "Allgäuer Alpen",
				"country": "Deutschland",
				"lat": 47.383874, 
				"lon": 10.430935,
				"date": ["2012", "2019"],
				"photo": "kreuzspitze.jpg",
				"description": "So cool wie ich die Kreuzspitze 2019 erlebt habe, hatte ich sie von 2012 nicht in Erinnerung. Ein Weg auf den Hochvogel verläuft über mit Drahtseil gesicherte Klettereipassagen an der Kreuzspitze vorbei. Der Abstecher mit 5 min auf den Gipfel lohnt sich immer. Von der Kreuzspitze ist man dem Hochvogel am Nächsten, von hier ist es nur noch eine Stunde auf einen der Könige der Allgäuer Alpen."
			},
			{
				"id": 14,
				"name": "Mädelegabel",
				"height": 2645,
				"group": "Zentraler Hauptkamm",
				"region": "Allgäuer Alpen",
				"country": "Deutschland/Österreich",
				"lat": 47.3, 
				"lon": 10.295833,
				"date": ["2015"],
				"photo": "madelegabel.jpg",
				"description": "Auf dem Bild sieht man mich vor dem Aufstieg zum Gipfel am alten Waltenberger Haus. Meine Schuhe waren komplett nass vom veregneten Vortag und auf dem Gipfel haben wir nichts gesehen, weil wir so in den Wolken waren. Trotzdem hat es einfach Spaß gemacht auf die Mädelegabel zu steigen. Sie ist eine der meistbestiegen Hochgipfel der deutschen Alpen. Von anderen Gipfeln erkennt man sie immer zusammen mit der spitzen Tretachspitze."
			},
			{
				"id": 15,
				"name": "Hohes Licht",
				"height": 2651,
				"group": "Zentraler Hauptkamm",
				"region": "Allgäuer Alpen",
				"country": "Österreich",
				"lat": 47.280556, 
				"lon": 10.276111,
				"date": ["2017"],
				"photo": "hohesLicht.jpg",
				"description": "Das Hohe Licht ist einfach zu erreichen vom Heilbronner Weg. So habe ich den Gipfel auf dem Weg von der Rappenseehütte zur Kemptnerhütte mitgenommen. Der Heilbronner Weg ist einer der schönsten Höhenwege im Allgäu."
			},
			{
				"id": 16,
				"name": "Großer Widderstein",
				"height": 2533,
				"group": "Südöstliche Walsertaler Berge",
				"region": "Allgäuer Alpen",
				"country": "Österreich",
				"lat": 47.285,
				"lon": 10.129167,
				"date": ["2019"],
				"photo": "widderstein.jpg",
				"description": "Nicht ganz ungefährlich ist die Besteigung vom Großen Widderstein. Die letzten 500 Meter geht es steil über Schutt, Fels und leichter Kletterei zum Gipfelkreuz. Ich war überrascht wie viele Menschen an diesem Samstag auf den Gipfel wollten. Lieber einen Helm mitnehmen wegen der akuten Steinschlaggefahr. Vom Gipfel aber hatte ich einen absolut fantastischen Blick auf den Allgäuer Hauptkamm, die Lechtaler Alpen mit der imposanten Holzgauer Wetterspitze und etwas weiter südlich war der massive Hohe Riffler sehr gut zu sehen."
			},
			{
				"id": 17,
				"name": "Pfänder",
				"height": 1062,
				"group": "",
				"region": "Allgäuer Alpen",
				"country": "Österreich",
				"lat": 47.506389, 
				"lon": 9.779167,
				"date": ["2019"],
				"photo": "pfander.jpg",
				"description": "Auf den Pfänder bin ich im Winter mit dem Schlitten hoch. Von hier hat man einen guten Blick auf den Bodensee."
			},
			{
				"id": 18,
				"name": "Gimpel",
				"height": 2173,
				"group": "Tannheimer Berge",
				"region": "Allgäuer Alpen",
				"country": "Österreich",
				"lat": 47.501271, 
				"lon": 10.612514,
				"date": ["2017"],
				"photo": "gimpel.jpg",
				"description": "Den Gimpel erreicht man auf dem leichtesten Weg mit Kletterei der Stufe II. Es war eine wunderschöne Herbsttour im Oktober aber trotzdem noch erstaunlich viel los."
			},
			{
				"id": 19,
				"name": "Besler",
				"height": 1679,
				"group": "Allgäuer Voralpen",
				"region": "Allgäuer Alpen",
				"country": "Deutschland",
				"lat": 47.425188,
				"lon": 10.188146,
				"date": ["2018"],
				"photo": "besler.jpg",
				"description": "Der Besler ist sehr einfach zu besteigen. Es war eine leichte, spätsommerliche Tagestour, auf der wir die Sonne und die Allgäuer Landschaft genießen konnten. Ich habe am Gipfel bestimmt eine Stunde mit der Kamera verbracht um die kräftig goldenen Farben des Spätsommers einzufangen."
			},
			{
				"id": 20,
				"name": "Biberkopf",
				"height": 2599,
				"group": "Zentraler Hauptkamm",
				"region": "Allgäuer Alpen",
				"country": "Deutschland/Österreich",
				"lat": 47.270528, 
				"lon": 10.232361,
				"date": ["2014"],
				"photo": "biberkopf.jpg",
				"description": "Der Biberkopf ist vom Dachboden meines Elternhauses an klaren Tagen sichtbar. Zusammen mit meinem Papa haben wir am Rappensee auf 2047 (See auf dem Bild) im Zelt übernachtet und sind am nächsten Tag auf den Gipfel. Das war eine wunderbare Wochenendtour, die ich nie vergessen werde."
			},
			{
				"id": 21,
				"name": "Samspitze",
				"height": 2624,
				"group": "",
				"region": "Lechtaler Alpen",
				"country": "Österreich",
				"lat": 47.173889,
				"lon": 10.395278,
				"date": ["2014"],
				"photo": "samspitze.jpg",
				"description": "Die Samspitze ist ein Hausberg der Ansbacher Hütte. Von dort ist sie leicht zu erreichen und nur war für uns ein 'kleiner Nachmittagsausflug' mit Gipfelkreuz."
			},
			{
				"id": 22,
				"name": "Darwinkopf",
				"height": 2970,
				"group": "",
				"region": "Lechtaler Alpen",
				"country": "Österreich",
				"lat": 47.166667,
				"lon": 10.466667,
				"date": ["2018"],
				"photo": "darwinkopf.jpg",
				"description": "Wer den (sehr anspruchsvollen) Augsburger Höhenweg gehen will, muss über den Darwinkopf. Über den Grat kommt man mit mittelschwerer Kletterei und wird mit einer grandiosen Aussicht auf die Parseierspitze und unvergesslichen Erlebnissen belohnt."
			},
			{
				"id": 23,
				"name": "Gatschkopf",
				"height": 2945,
				"group": "",
				"region": "Lechtaler Alpen",
				"country": "Österreich",
				"lat": 47.173333, 
				"lon": 10.489167,
				"date": ["2018"],
				"photo": "gatschkopf.jpg",
				"description": " Eigentlich ist der Gatschkopf, so wie der Name schon klingt ein nicht besonders schöner schwarzer und flacher Gipfel. Man kann ihn aber einfach auf dem Höhenweg vom Württemberger Haus oder von der Memminger Hütte zur Augsburger Hütte mitnehmen."
			},
			{
				"id": 24,
				"name": "Einstein",
				"height": 1866,
				"group": "Tannheimer Berge",
				"region": "Allgäuer Alpen",
				"country": "Österreich",
				"lat": 47.522222, 
				"lon": 10.513333,
				"date": ["2015"],
				"photo": "einstein.jpg",
				"description": "Der Einstein ist ein leicht besteigbarer Berg, den ich Winter(!) 2015 mit meinen Eltern hoch bin. Es war superschönes Wetter und fast alles schneefrei."
			},
			{
				"id": 25,
				"name": "Bola del Mundo",
				"height": 2257,
				"group": "Sierra de Guadarrama",
				"region": "Sistema Central",
				"country": "Spanien",
				"lat": 40.785000, 
				"lon": -3.979000,
				"date": ["Dez. 2016"],
				"photo": "bolaDelMundo.jpg",
				"description": "Mein erster Gipfel in Spanien! Ich wollte unbedingt in die Berge bei Madrid. Obwohl es an diesem Tag kalt und nass war und am Gipfel die Sicht schlecht, war es doch ein sehr schöner Tag. In der Hütte nahe am Gipfel gabs dann noch typische Churros mit heißer Schoki. So lecker!"
			},
			{
				"id": 26,
				"name": "Castillo de La Mota",
				"height": "~ 100",
				"group": "",
				"region": "San Sebastian",
				"country": "Spanien",
				"lat": 43.324868, 
				"lon": -1.98921,
				"date": ["Jan. 2016"],
				"photo": "sanSebastian.jpg",
				"description": "Eigentlich kein Gipfel, jedoch höchter Punkt einer Erhebung mit einer fantastischen Aussicht auf die Muschel (la concha) von San Sebastián."
			},
			{
				"id": 27,
				"name": "Vârful Podragu",
				"height": 2482,
				"group": "Făgăraș Gebirge",
				"region": "Karpaten",
				"country": "Rumänien",
				"lat": 45.603712, 
				"lon": 24.685495,
				"date": ["2016"],
				"photo": "podragu.jpg",
				"description": "Auf dem Weg zum höchsten Berg Rumäniens, dem Moldoveanu, haben wir es leider wegen des Wetters nur bis zur Hütte Podragu geschafft. Zu dieser Hütte wird noch alles mit Eseln den Berg hinauf transportiert. Doch auf dem Heimweg haben wir noch den Vârful Podragu mitnehmen können."
			},
			{
				"id": 28,
				"name": "Vulkan Irazú",
				"height": 3432,
				"group": "",
				"region": "nahe der Hauptstadt San José",
				"country": "Costa Rica",
				"lat": 9.979833, 
				"lon": -83.849140,
				"date": ["2019"],
				"photo": "irazu.jpg",
				"description": "Der Vulkan zählt zu den gefährlichsten und unberechenbarsten Vulkanen Costa Ricas. Eine Besonderheit dieses Vulkans ist, dass man von seiner Spitze aus an klaren Tagen zwei Meere, das Karibische Meer und den Pazifik, sehen kann. Wir sind mit dem Bus hoch auf knapp über 3000 und am nächsten Tag zum Krater gelaufen. Zwei Tage, die ich nie vergessen werde, denn an jenem Abend hab ich mich mit dem Mann meines Herzens verlobt."
			},
			{
				"id": 29,
				"name": "Cerro Amigos",
				"height": 1840,
				"group": "",
				"region": "Monte Verde",
				"country": "Costa Rica",
				"lat":  10.318217, 
				"lon": -84.793578,
				"date": ["2019"],
				"photo": "cerroAmigos.jpg",
				"description": "Umgeben von echten Nebelwald ist es feucht und warm. Unglaublich in so einer Fülle von Grün zu sein, Kolibris in freier Wildbahn zu sehen und ihren Flügelschlag zu spüren. Vom Gifel aus sieht man tatsächlich nichts außer Nebel und Wald. In der Stadt Monte Verde kann man jedoch bis zum Pazifk sehen."
			},
			{
				"id": 30,
				"name": "Vârful Cozia",
				"height": 1688,
				"group": "Massiv Cozia",
				"region": "Karpaten",
				"country": "Rumänien",
				"lat":  45.318839, 
				"lon": 24.338054,
				"date": ["2016"],
				"photo": "cozia.jpg",
				"description": "Im Nationalpark Cozia habe ich während meinem europäischen freiwilligen Jahr Müll eingesammelt. Dazu hab ich mit den anderen Freiwilligen aus meiner Gruppe eine kleine Broschüre über diesen schönen Nationalpark heruasgebracht."
			},
			{
				"id": 31,
				"name": "Akdag",
				"height": 1280,
				"group": "",
				"region": "Kappadokien",
				"country": "Türkei",
				"lat":  38.666619, 
				"lon": 34.855087,
				"date": ["2016"],
				"photo": "cappadocia.jpg",
				"description": "Kappadokien ist ein Traum. Sehr typisch sind die Ballonfahrten, die hier teuer für die Touristen angeboten werden. Die Ballone steigen bei Sonnenaufgang gen Himmel und noch viel schöner als mit so einem Heißluftballon zu fliegen ist die günstigere Variante. Wir sind am Abend gekommen, haben unser Zelt aufgeschlagen und haben uns morgens um halb 5 angschaut, wie es hell wurde und leuchtende Luftballone langsam in die Höhe gestiegen sind. Das Bild entstand am Abend davor."
			},
			{
				"id": 32,
				"name": "Inselüberquerung Ägina",
				"height": "~ 500",
				"group": "",
				"region": "Saronischer Golf (Golf von Ägina)",
				"country": "Griechenland",
				"lat":  37.746377, 
				"lon": 23.486853,
				"date": ["2016"],
				"photo": "aegina.jpg",
				"description": "Weil wir den letzten Bus des Tages verpasst hatten und usnere Unterkunft am anderen Ende der Insel lag, haben wir uns spontan entschlossen die 10 km bis zum anderen Ende zu Fuß zu laufen. Eine großartige Entscheidung und wir fahren fast am Gipfel. ;)"
			},
			{
				"id": 33,
				"name": "Sass Rigais",
				"height": 3025,
				"group": "Geislergruppe",
				"region": "Dolomiten",
				"country": "Italien",
				"lat":  46.609441, 
				"lon": 11.766736,
				"date": ["2020"],
				"photo": "sassRigais.jpg",
				"description": "Ein 3000er in Südtirol mit einem wunderschönen Klettersteig. Der Tag war herrlich und die Sicht auf die umliegenden Dolomitenberge fantastisch. Im Hintergrund ist das Sella Massiv zu sehen. Wir wären ein einem anderen Tag auch dort gewesen, wenn es ein paar Tage zuvor nicht geschneit hätte... man sieht ja den Schnee. Und das im August :D"
			},
			{
				"id": 34,
				"name": "Paternkopfel",
				"height": 2744,
				"group": "Naturpark Drei Zinnen",
				"region": "Sextner Dolomiten",
				"country": "Italien",
				"lat":  46.625206, 
				"lon": 12.315278,
				"date": ["2020"],
				"photo": "paternkofel.jpg",
				"description": "Wir sind an den Drei Zinnen vorbei, weg von den Menschenmassen, durch Tunnel, Stellungen und Steiganlagen des Ersten Weltkriegs durch einem Klettersteig auf den Paternkofel. Von dort hat man einen gigantischen Blick auf die berühmten Drei Zinnen."
			},
			{
				"id": 35,
				"name": "Tobliner Knoten",
				"height": 2617,
				"group": "Naturpark Drei Zinnen",
				"region": "Sextner Dolomiten",
				"country": "Italien",
				"lat":  46.641725, 
				"lon": 12.307884,
				"date": ["2020"],
				"photo": "toblingerknoten.jpg",
				"description": "Auf den Toblinger Knoten muss man nicht, aber man kann. Ein kleiner aber interessanter Gipfel oberhalt der Drei Zinnen Hütte. Mit vielen Leitern und ein bisschen Kletterei, und viel Kraft in den Armen kommt man hoch."
			},
			{
				"id": 36,
				"name": "Punta Fiames",
				"height": 2240,
				"group": "",
				"region": "Dolomiten",
				"country": "Italien",
				"lat":  46.576022, 
				"lon": 12.129773,
				"date": ["2020"],
				"photo": "puntafiames.jpg",
				"description": "Über den Klettersteig Via ferrata Michielli-Strobel kann man zum Gipfel kommen. Das haben wir gemacht und es ist ein Klettersteig, der sich durch die Westwand zieht und der richtig Spaß macht! Ganz zu schweigen von der Aussicht auf Cortina d'Ampezzo."
			},
			{
				"id": 37,
				"name": "Erster und zweiter Schafalpenkopf",
				"height": 2320,
				"group": "Südöstliche Walsertaler Berge",
				"region": "Allgäuer Alpen",
				"country": "Deutschland",
				"lat":  47.306126, 
				"lon": 10.210299,
				"date": ["2019"],
				"photo": "schafalpenkopf.jpg",
				"description": "Mit dem Mindelheimer Klettersetig geht es über den ersten, dann den zweiten Schafalpenkopf und schließlich über das Kemptner Köpfle. Durchgehend hat man einen fantastischen Rundumblick, dann man geht genau am Grat entlang."
			},
			{
				"id": 38,
				"name": "Kemptner Köpfle",
				"height": 2191,
				"group": "Südöstliche Walsertaler Berge",
				"region": "Allgäuer Alpen",
				"country": "Deutschland",
				"lat":  47.297278, 
				"lon": 10.199185,
				"date": ["2019"],
				"photo": "kemptnerkopf.jpg",
				"description": "Mit dem Mindelheimer Klettersetig geht es über den ersten, dann den zweiten Schafalpenkopf und schließlich über das Kemptner Köpfle. Durchgehend hat man einen fantastischen Rundumblick, dann man geht genau am Grat entlang."
			},
			{
				"id": 39,
				"name": "Hirschberg",
				"height": 1500,
				"group": "Voralpen östlich der Iller",
				"region": "Allgäuer Alpen",
				"country": "Deutschland",
				"lat":  47.518144, 
				"lon": 10.376944,
				"date": ["2020"],
				"photo": "hirschberg.jpg",
				"description": "Glitzernd schweben die Schneekristalle in der kalten Luft eines Januar-Tages. Sie Sonne kommt zwischen den Wolken hervor und verzaubert die Winterlandschft."
			},
			{
				"id": 40,
				"name": "Sfinx",
				"height": 2216,
				"group": "Nationalpark Bucegi",
				"region": "Karpaten",
				"country": "Rumänien",
				"lat":  45.407973, 
				"lon": 25.470829,
				"date": ["2015"],
				"photo": "sfinx.jpg",
				"description": ""
			},
			{
				"id": 41,
				"name": "Hocheck",
				"height": 2651,
				"group": "Watzmannmassiv",
				"region": "Berchtesgadener Alpen",
				"country": "Deutschland",
				"lat": 47.558293,
				"lon": 12.923856,
				"date": ["2021"],
				"photo": "watzmannnordgipfel.jpg",
				"description": ""
			},
			{
				"id": 42,
				"name": "Marchspitze",
				"height": 2609,
				"group": "Hornbachkette",
				"region": "Allgäuer Alpen",
				"country": "Deutschland",
				"lat": 47.315,
				"lon": 10.374722,
				"date": ["2021"],
				"photo": "marchspitze.jpg",
				"description": ""
			},
			{
				"id": 43,
				"name": "Sierra Grossa",
				"height": 173,
				"group": "",
				"region": "Alicante",
				"country": "Spanien",
				"lat": 38.361376, 
				"lon": -0.454296,
				"date": ["2022"],
				"photo": "alicante.jpg",
				"description": ""
			},
			{
				"id": 44,
				"name": "Peñalara",
				"height": 2428,
				"group": "Sierra de Guadarrama",
				"region": "Sistema Central",
				"country": "Spanien",
				"lat": 40.850098, 
				"lon": -3.955898,
				"date": ["2022"],
				"photo": "penalara.jpg",
				"description": ""
			},
			{
				"id": 45,
				"name": "Ruine Hohenurach",
				"height": 692,
				"group": "",
				"region": "Bad Urach",
				"country": "Deutschland",
				"lat": 48.493333,
				"lon": 9.378333,
				"date": ["2022"],
				"photo": "ruinehohenurach.jpg",
				"description": ""
			},
			{
				"id": 46,
				"name": "Lagos de Covadonga",
				"height": 1134,
				"group": "Parque Nacional de los Picos de Europa",
				"region": "Asturias",
				"country": "Spanien",
				"lat": 43.270747, 
				"lon": -4.985930,
				"date": ["2021"],
				"photo": "covadonga.jpg",
				"description": ""
			},
			{
				"id": 47,
				"name": "La Maliciosa",
				"height": 2227,
				"group": "Sierra de Guadarrama",
				"region": "Sistema Central",
				"country": "Spanien",
				"lat": 40.767083, 
				"lon": -3.968506,
				"date": ["2021"],
				"photo": "maliciosa.jpg",
				"description": ""
			},
			]
		},
		m: {
				"id": 1,
				"name": "Hochvogel",
				"height": 2592,
				"group": "Hochvogel- und Rosszahngruppe",
				"region": "Allgäuer Alpen",
				"country": "Deutschland/Österreich",
				"lat": 47.3803776,
				"lon": 10.4345785,
				"date": ["2012", "2015", "2019"],
				"photo": "hochvogel.jpg",
				"description": "Wahrscheinlich wegen seiner unverkennbaren Form einer Pyramide, seiner Dominanz und seiner Nähe ist er mein Lieblingsberg. Schon 3 1/2 Mal war ich oben. Inzwischen ist ein riesiger Spalt am Gipfel, sodass in naher Zukunft ein Teil des Gipfels mit bis zu 260.000 Kubikmetern abstürzen wird. Der Hochvogel war der erste Berg, den ich auch von anderen Gipfel aus erkennen konnte."
			}
		}
	},
	mounted() {
		//this.calculateDividerWidth();
		document.getElementById("mountain-intro").style.right = "calc(-40% - 50px)";
		let el = document.getElementsByClassName("legend-point-wrapper")[0]; 
		el.addEventListener('mouseover', this.cursorEnter());
		el.addEventListener('mouseleave', this.cursorOut());
		document.getElementById("nav").style.display = "none";
		let moreNav = document.getElementsByClassName("more-links")[0];
		if (moreNav) {
			moreNav.style.display = "none";
		}

		let height = "innerHeight" in window  ? window.innerHeight : document.documentElement.offsetHeight; 
		let width = "innerWidth" in window  ? window.innerWidth : document.documentElement.offsetWidth; 
		if (height/width >=1 ) {
			this.mobile = true;
			this.zoom = 2;
		}
	},
	methods: {
		cursorEnter() {
            this.$store.commit("setMouseOverLink", true);
        },
        cursorOut(){
            this.$store.commit("setMouseOverLink", false);
        },
		closeSidebar() {
			this.removeActive(this.m);
			if (this.mobile) {
				document.getElementById("mountain-intro").style.top = "calc(100% + 50px)";
			} else {
				document.getElementById("mountain-intro").style.right = "calc(-40% - 50px)";
			}
			setTimeout(() => {
				this.m = null;
			}, 600);
		},
		getImgUrl(allM) {
			return require(`@/assets/Bilder/Berge/` + allM.photo );
		},
		checkToShow(allM) {
			return allM.id == this.m.id;
		},
		zoomUpdate: function(zoom) {
			this.currentZoom = zoom;
		},
		centerUpdated (center) {
			this.center = center;
		},
		setColor(height) {
			height = parseInt(height);
			if (height < 2000) {
				return "#ee5894";
			}
			else if ( 2000 <= height && height < 2500){
				return "#DB095D";
			}
			else if ( 2500 <= height && height < 3000){
				return "#A80747";
			} 
			else if ( 3000 <= height && height< 3500){
				return "#8F063D";
			}
			else if ( 3500 <= height){
				return "#6B042D";
			} else {
				return "#ee5894";
			}
		},
		showMountain(m)  {
			this.m = m;
			this.mountains.mountains.forEach(mountain => {
				document.getElementById("heart" + mountain.id).style.display = "none";
			});
			let id = "heart" + m.id;
			document.getElementById(id).style.display = "inline";
			let sidebar = document.getElementById("mountain-intro");
			if (this.mobile) {
				sidebar.style.top = "calc(50% - 50px)"
			} else {
				if (sidebar.style.right) {
					sidebar.style.right = 0;
				}
			}
			this.calculateDividerWidth();
		},
		calculateDividerWidth() {
			setTimeout(function(){ 
				let elements = document.getElementsByClassName("mountain-place");
				let elementsWidths = [];
				elements.forEach(element => { 
					elementsWidths.push(element.offsetWidth);				
				});
				let max = Math.max(...elementsWidths);
				document.getElementById("animated-line").style.width = max + "px"; 
			}, 200);
			
		},
		showHeart(m) {
			let id = "heart" + m.id;
			document.getElementById(id).style.display = "inline";
		},
		removeActive(m) {
			if (this.m != null && m.id != this.m.id) {
				let id = "heart" + m.id;
				document.getElementById(id).style.display = "none";
			}
		},
		besteigungen(dates) {
			let date = "date";
			let l = dates.length;
			if (l > 1) {
				date = "Besteigungen: ";
				for(var i=0; i<l-1; i++) {
					date += dates[i] + " | ";
				}
				date += dates[l-1]
			} else {
				date = "Besteigung: " + dates[0];
			}
			return date;
		}
	}
}
</script>

<style lang="scss" scoped>
$pink: #CA085E;
$lightblue: #C4D1DB;
$midblue: #7AA3C8;
$skyblue: #4476AE;
$darkblue: #031940;
$blackblue: #010819;
$grey: #131313;
$greya: #131313df;

$c1: #ee5894;
$c2: #DB095D;
$c3: #A80747;
$c4: #8F063D;
$c5: #6B042D;
$c6: #5C1F37;

.explore {
	margin: 0 20px 20px 20px;
	width: calc(100vw - 40px);
	height: calc(100vh - 20px);
}
#map-wrapper {
	position: relative;
	height: 100%;
	width: 100%;
	#map {
		overflow: hidden;
		z-index: 50;
		position: relative;
		top: 0;
		left: 0;
		cursor: none;
	}
	::v-deep .leaflet-touch .leaflet-bar {
		border-color: transparent;
		border-radius: 5px;
	}
	::v-deep .leaflet-control-zoom-in, ::v-deep .leaflet-control-zoom-out {
		border: none;
		cursor: none;
		background-color: $greya;
		color: white;
		font-weight: 200;
	}
	#legend {
		z-index: 79;
		position: absolute;
		top: 11px;
		left: 60px;
		border-radius: 5px;
		padding: 10px;
		background-color: $greya;
		color: white;
		display: flex;
		flex-direction: column;
		.legend-point-wrapper {
				display: flex;
				flex-direction: row;
				align-items: center;
				font-size: 0.8em;
			p {
				position: relative;
				top: -1px;
				margin: 0;
				padding-left: 10px;
				font-weight: 300;
			}
		}
		.legend-point {
			width: 10px;
			height: 10px;
			border-radius: 20px;
		}
		#legend-0000 {
			background-color: $c1;
		}
		#legend-2000 {
			background-color: $c2;
		}
		#legend-2500 {
			background-color: $c3;
		}
		#legend-3000 {
			background-color: $c4;
		}
		#legend-3500 {
			background-color: $c5;
		}
	}
	.heart {
		display: none;
		width: 100%;
		transition: display 0.2s ease-out;
	}
	.micon-wrapper {
		position: relative;
		top: 0px;
		left: 0px;
	}
	.map-single-mountain {
		z-index: 89;
		position: absolute;
		top: 0px;
		left: 0px;
		width: 15px;
		height: 15px;
		overflow: hidden;
		background-color: #29246a;
		border-radius: 35px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		transform: scale(1);
		box-Shadow: 0 0 7px -1px rgb(212, 212, 212);
		transition: all 0.3s;
	}
	.map-single-mountain:hover {
		z-index: 999;
		transform: scale(1.5);
		box-Shadow: 0 0 10px 0px #fff;
		cursor: none;
	}
	.resize {
		height: 60%;
		width: auto;
		overflow: hidden;
	}
}

#mountain-intro {
	position: fixed;
	top: 0;
	right: calc(-40% - 50px);
	z-index: 100;
	width: 40%;
	height: 100%;
	margin: 0 0 0 100px;
	background-color: $greya; 
	box-shadow: 0 0 40px -5px $grey;
	box-sizing: border-box;
	padding: 40px 0;
	transition: all 1s ease;
	.close {
		position: absolute;
		top: 11px;
		left: -50px;
		font-size: 1.5rem;
		background-color: $greya;
		width: 50px;
		height: 40px;
		padding: 9px 0 0 15px;
		line-height: 20px;
		box-sizing: border-box;
		border-radius: 5px 0 0 5px;
		font-weight: 500;
		color: $pink;
	}
	.single-mountain {
		height: 100%;
		overflow-y: auto;
		p {
			font-weight: 300;
		}
		h2 {
			font-weight: 200;
			margin: 0 0 10px 40px;
		}
		.horizontal-divider {
			border-bottom: 1px solid $pink;
			margin-bottom: 10px;
			width: 70px;
			transition: 0.7s ease;
		}
		.animated-line {
			width: 100px;
		}
		.info {
			margin: 0 20px 40px 40px;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: baseline;   
			.height {
				width: 70px;
			}
			p {
				margin: 0;
			}
			span {
				font-weight: 300;
			}
		}
		.img-mountain {
			height: 25vw;
			width: 100%;
			object-fit: cover;
			z-index: 51;
		}
		#map-text {
			margin: 0 40px;
			max-width: 800px;
			.description {
				margin-top: 30px;
			}
		}
		.bold {
			font-weight: 700;
		}
	}
}
.single-mountain::-webkit-scrollbar {
  width: 0;
}
 
.single-mountain::-webkit-scrollbar-track {
  box-shadow: none;
  -ms-box-shadow: none;
  -webkit-box-shadow: none;
}

@media screen and (max-width: 800px) { 
	.explore {
		margin: 0;
		width: 100vw;
		height: 100vh;
	}

	#mountain-intro {
		position: fixed;
		top: calc(100% + 50px);
		left: 0;
		z-index: 100;
		width: 100%;
		height: 50%;
		margin: 50px 0 0 0;
		padding: 0;
		.close {
			top: -40px;
			left: calc(50% - 50px);
			width: 100px;
			height: 40px;
			padding: 10px 45px;
			border-radius: 5px 5px 0 0;
		}
		.single-mountain {
			padding: 20px;
			h2 {
				margin: 0 0 10px 0;
			}
			.info {
				margin: 0 0 40px 0;
			}
			.img-mountain {
				height: 40vh;
				width: 100%;
			}
			#map-text {
				margin: 0;
				.description {
					margin-bottom: 50px;
				}
			}
		}
	}
}
</style>
