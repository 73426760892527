<template>
  <div class="notFound page">
        <MainMenu />
        <div class="hero">
            <img src="./../assets/Bilder/Lisa/IMG_5183_lang_1.jpg" alt="Webdesiger und Webentwicklerin Hero">
        </div>
        <div class="welcome-wrapper">
            <div class="headline">
                <h2> error 404: nicht gefunden</h2>
            </div>
            <section class="welcome">
                <p> Diese Seite existiert leider nicht. <br>
                Was suchst du?</p>
                <div id="not-found-menu">
                    <router-link to="/start">
                        <div class="menu-link fat" id="menu-start" @click="goTo('start')"
                            @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                            <h3> START </h3>  
                        </div>
                    </router-link>
                    <router-link to="/home">
                        <div class="menu-link fat" id="menu-websites" @click="goTo('websites')"
                            @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                            <h3> ÜBER LISA  </h3>   
                        </div>
                    </router-link>
                    <router-link to="/home#superpower">
                        <div class="menu-link fat" id="menu-superpower"  @click="goTo('superpower')"
                            @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                            <h3> STÄRKEN </h3>    
                        </div>
                    </router-link>
                    <router-link to="/home#projekte">
                        <div class="menu-link fat" id="menu-projects"  @click="goTo('mprojectsehr')"
                            @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                            <h3> AUSGEWÄHLTE PROJEKTE </h3>   
                        </div>
                    </router-link>
                    <router-link to="/home#kontakt">
                        <div class="menu-link fat" id="menu-more"  @click="goTo('contact')"
                            @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                            <h3> KONTAKT </h3>   
                        </div>
                    </router-link>
                </div>
            </section>
        </div>        
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import MainMenu from '@/components/Menu.vue'

export default {
    name: 'Impressum',
    components: {
        MainMenu,
        Footer
    },
    methods: {
        toHome() {
            return "/" + this.$store.state.homeState;
        },
        cursorEnter() {
            this.$store.commit("setMouseOverLink", true);
        },
        cursorOut(){
            this.$store.commit("setMouseOverLink", false);
        },
        goTo(link) {
            if (link == "websites") {
                this.$store.commit("setHomeState", "websites");
            } else if (link == "shootings") {
                this.$store.commit("setHomeState", "shootings");
            }
            this.cursorOut();
        },
    }
}
</script>

<style lang="scss" scoped>
$pink: #CA085E;
$lightblue: #C4D1DB;
$midblue: #7AA3C8;
$skyblue: #4476AE;
$darkblue: #031940;
$blackblue: #010819;
$grey: #131313;

.hero {
    width: calc(100vw - 40px);
    height: 50vh;
    margin: 0 20px 20px 20px;
    overflow: hidden;
    text-align: left;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }
}

.welcome-wrapper {
    position: relative;
    .headline {
        text-align: center;
        position: absolute;
        top: -8.5rem;
        left: 0;
        width: 100%;
        text-align: center;
    }
}

.welcome {
    margin: 20px;
    background-color: $lightblue;
    text-align: center;
    color: $blackblue;
    padding: 3rem 20px;
    p {
        font-weight: 400;
        text-align: center;
        
    }
    #not-found-menu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        transition: all 0.5s ease;
        padding: 3rem 0 2rem 0;
        div {
            font-size: 2rem;
            transition: all 0.2s ease;
        }
        div:hover {
            color: $skyblue !important;
        }
        .fat {
            font-weight: 800;
        }
        .menu-link {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: start;
            flex-wrap: wrap;
            margin: 0 20px;
        }
    }
}

@media screen and (max-width: 1300px) { 
    .welcome {
        #not-found-menu {
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 700px) {
    .hero {
        width: 100%;
        margin: 0;
    }
    .headline h2 {
        text-align: left;
        padding: 0 20px;
    }
    .welcome {
        margin: 20px 0;
        p {
            text-align: left;
        }
        #not-found-menu {
            justify-content: start;
            align-items: flex-start;
            .menu-link {
                margin: 5px 0;
                text-align: left;
                h3 {
                    line-height: 120%;
                }
            }
        }
    }
}

</style>
