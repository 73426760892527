<template>
    <div id="main-menu">
            <router-link class="start-link" to="/start">
                <div class="menu-link fat" id="menu-start" @click="goTo('start')"
                    @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                    <div class="line" v-if="location == 'start'"></div> 
                    START 
                    <div class="line" v-if="location == 'start'"></div> 
                </div>
            </router-link>
            <router-link to="/home">
                <div class="menu-link fat" id="menu-website" @click="goTo('website')"
                    @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                    <div class="line" v-if="location == 'website'"></div> 
                    ÜBER LISA 
                    <div class="line" v-if="location == 'website'"></div>
                </div>
            </router-link>
            <router-link to="/home#superpower">
                <div class="menu-link fat" id="menu-superpower" @click="goTo('superpower')"
                    @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                    <div class="line" v-if="location == 'superpower'"></div> 
                    STÄRKEN 
                    <div class="line" v-if="location == 'superpower'"></div> 
                </div>
            </router-link>
            <router-link to="/home#projekte">
                <div class="menu-link fat" id="menu-projects"  @click="goTo('projects')"
                    @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                    <div class="line" v-if="location == 'projects'"></div> 
                    AUSGEWÄHLTE PROJEKTE
                    <div class="line" v-if="location == 'projects'"></div>
                </div>
            </router-link>
            <router-link to="/home#kontakt">
                <div class="menu-link fat" id="menu-contact"  @click="goTo('kontakt')"
                    @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                    <div class="line" v-if="location == 'contact'"></div> 
                    KONTAKT 
                    <div class="line" v-if="location == 'contact'"></div> 
                </div>
            </router-link>
            <!--
            <router-link to="/mehr">
                <div class="menu-link fat" id="menu-more"  @click="goTo('mehr')"
                    @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                    <div class="line" v-if="location == 'more'"></div> 
                    MEHR 
                    <div class="line" v-if="location == 'more'"></div>
                </div>
            </router-link>
             -->
    </div>
</template>

<script>
export default {
    name: "Menu",
    components: {
    },
    data() {
        return {
            location: ""
        }
    },
    mounted() {
       this.renderMenu()
    },
    methods: {
        goTo(link) {
            this.$store.commit("toggleMenu", false);
            document.getElementById("hamburger-menu").classList.toggle('animate');
            this.setColor("#ffffff");
            this.renderMenu();
            /*
            if (link == "website") {
                this.$store.commit("setHomeState", "website");
            } else if (link == "shootings") {
                this.$store.commit("setHomeState", "website");
            }*/
            this.cursorOut();
        },
        renderMenu() {
            this.location = this.$router.currentRoute.name; 
            this.setColor("#4476AE");
        },
        setColor(color) {
            console.log(this.$router.currentRoute.fullPath);
            switch (this.location) {
                case "start":
                    document.getElementById("menu-start").style.color = color;
                    break;
                case "website":
                    document.getElementById("menu-website").style.color = color;
                    break;
                case "superpower":
                    document.getElementById("menu-superpower").style.color = color;
                    break;
                case "shootings":
                    document.getElementById("menu-shootings").style.color = color;
                    break;
                case "more":
                    document.getElementById("menu-more").style.color = color;
                    break;
                case "contact":
                    document.getElementById("menu-contact").style.color = color;
                    break;
                case "projects":
                    document.getElementById("menu-projects").style.color = color;
                    break;
                default:
                    break;
            }
        },
        cursorEnter() {
            this.$store.commit("setMouseOverLink", true);
        },
        cursorOut(){
            this.$store.commit("setMouseOverLink", false);
        }
    }
}
</script>

<style lang="scss" scoped>
$grey: #131313;
$skyblue: #4476AE;

#main-menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 0vh;
    background-color: #131313;
    box-shadow: 0 0 20px -5px black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: all 0.5s ease;
    div {
        font-size: 2.5rem;
        transition: all 0.2s ease;
    }
    div:hover {
        color: $skyblue !important;
    }
    .fat {
        font-weight: 800;
    }
    .menu-link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .line {
        width: 60px;
        height: 0;
        border-bottom: 1.5px solid $skyblue;
        margin: 20px;
    }
}

@media (max-width: 700px) {
    #main-menu {
        justify-content: center;
        div {
            font-size: 1.5rem;
        }
        .line {
            width: 30px;
        }
    }
}
</style>