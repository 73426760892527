<template>
    <div class="more">
        <MainMenu />

        <div class="hero hero-cite">
            <img src="./../assets/Bilder/More/DSC00340-1-2.jpg" alt="Webdesiger und Webentwicklerin Hero">
        </div>
        <div class="welcome-wrapper">
            <div class="headline">
                <h2> Mehr über mich</h2>
            </div>
        </div>
        <section class="more-cite">
            <h2 class="letterwriting letterwriting-desktop"> Ich  glaube  an  ein  Leben  voller  Fülle,  
            die  ihre  <br> Quelle in  unsere  
            Einzigartigkeit  findet  und  sich  durch <br> das  Leben  
            in  Gemeinschaft  manifestiert.
            </h2>
            <h2 class="letterwriting letterwriting-mobile"> Ich  glaube  an  ein  Leben  voller  Fülle,  
            die  ihre  Quelle in  unsere  
            Einzigartigkeit  findet  und  sich  durch das  Leben  
            in  Gemeinschaft  manifestiert.
            </h2>
        </section>
        <section class="content">
            <p> 90% meiner Morgens starte ich mit einem halben Liter Zitronenwasser. 
                Ich trinke lieber Tee statt Kaffee und tanke meine Energie in der Natur 
                oder in der Nähe eines guten Freundes.
            </p>
            <p> Für mich ist die Welt voller Wunder und Glück. 
                Ich strebe nach Liebe und Freude in meinen Beziehungen und 
                in allem, was ich tue. 
            </p> 
            <p>
                Ich habe eine schnelle Auffassungsgabe, bin flexibel und offen 
                für Veränderung. Beruflich steckt meine Liebe vor allem im Design, 
                der Technologie und der Fotografie. 
            </p>
        </section>
        <section class="strength">
            <h3> Meine Stärken </h3>
            <div class="phones"> 
                <div class="phone-content">
                    <div class="phone basic design">
                        <div>
                            <div class="screen-top">
                                <div class="speakers"></div>
                                <div class="camera"></div>
                            </div>
                            <h5 class="headline"> Design </h5>
                            <div class="content">
                                Ich liebe es neue Oberflächen 
                                zu entwerfen, zu gestalten, mit Farben zu arbeiten und 
                                ständig besser zu werden.
                                <br> <br>
                                Du bekommst von mir, ein auf Dich ausgerichtetes Design, ansprechende Benutzeroberflächen, 
                                aus hochwertigem Design, die nicht nur schön aussieht, 
                                sondern auch funktional sind. 
                                Dein Kunde darf sich wohl fühlen.
                            </div>
                        </div>
                        <div class="topline"></div>
                    </div>
                    <div class="phone basic fotografie">
                        <div>
                            <div class="screen-top">
                                <div class="speakers"></div>
                                <div class="camera"></div>
                            </div>
                            <h5 class="headline"> Fotografie </h5>
                            <div class="content">
                                Ich liebe es mit der Kamera in der Hand Aspekte dieser Welt 
                                einzufangen und die Bilder für sich selbst sprechen zu lassen.
                                <br> <br>
                                Bilder sind sehr vielfältig und können viel schneller 
                                viel mehr vermitteln. 
                                <br> <br>
                                Bilder können in Sekundenschnelle ansprechen und ein 
                                Gefühl von Begeisterung aktivieren. Bilder berühren.
                            </div>
                        </div>
                        <div class="topline"></div>
                    </div>
                    <div class="phone basic coding">
                        <div>
                            <div class="screen-top">
                                <div class="speakers"></div>
                                <div class="camera"></div>
                            </div>
                            <h5 class="headline"> Coding </h5>
                            <div class="content">
                                Ich liebe es zu Coden, da sich hier. speziell im Web, Sprache, Logik und Design vermischen.
                                <br> <br>
                                Die erste Eindruck einer Website ist wichtig, doch ist die Funktionalität ist das A und O.  
                                <br> <br>
                                Mit JavaScript und CSS beherrsche ich zwei starke Sprachen des Webs, die diese Funktionalität leicht machen und Deine Individualität in den Vordergrund stellen können.
                            </div>
                        </div>
                        <div class="topline"></div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="content">
            <p> Was ich mit Dir teilen will ist: Du bist weder Bronze, 
                noch Silber oder Gold. Du bist Platin.
            </p>
            <h4> DU BIST EINE PLATINEDITION </h4>
            <p> Jeder einzelne von ist das, doch oft denken wir selbst gar 
                nicht so über uns selbst. Das kenne ich am Besten von mir selbst. 
            </p> 
            <p> Du bist aber wichtig. 
                Du als Person. Oder Dein Business. Und mir ist es wichtig, 
                Dich in deinem Onlineauftritt bestmöglich zu unterstützen. 
                Lass uns gemeinsam deine Marke und deine Werte 
                nach außen tragen. 
            </p>
            <h4> IM TEAM GEWINNEN </h4>
            <p> Seit ich klein bin, bin ich regelmäßig in den Bergen und ich habe gelernt, 
                dass ich zusammen mit anderen Menschen viel mehr gewinne.
                <br> Meine Leidenschaft zu den Bergen hat mich schon tief bewegt, 
                mir viele Erkenntnisse und Herausforderungen geschenkt. Sie hat mich an meine Grenzen gebracht und mir Momente des tiefen Friedens gebracht.
            </p>
            <p> Nur alleine war vieles nicht möglich. Ich brauchte die Unterstützung der Menschen, 
                die mit mir unterwegs waren.
            </p>
            <router-link to="/berge" target="_blank">
                <p class="mountains-link" @mouseenter="cursorEnter" @mouseleave="cursorOut"> Entdecke meine Abenteuer in den Bergen.
                    <img class="arrow-left" src="../assets/Icons/arrow_right.png" alt="Pfeil links">
                </p>
            </router-link>
            <p> Und auch im Business sind wir im Team stärker und gelangen weiter und höher, 
                als wir es je allein könnten.
            </p>
            <h4> Wie kann ich Dich bestmöglich unterstützen? </h4>
        </section>
        <div class="hero hero-cite cite-third">
            <div class="hero-cite-desktop"> 
                <h2>
                    "Frau Miller arbeitete mit Ihren Kollegen
ausgezeichnet im leam zusammen. Sie besitzt ein umtassendes Wissen, welches das eigene
Fachgebiet uberschreitet. Sie war daher stets eine wichtige Ansprechperson."
                </h2>
            </div>
            <div class="hero-cite-mobile"> 
                <h2>"Nutzer 
                    <br> entscheiden in
                    <br>  Bruchteilen
                    <br> von Sekunden, 
                    <br> ob sie auf 
                    <br> einer Website 
                    <br> bleiben, 
                    <br> oder ob sie
                    <br> gehen..."
                </h2>
            </div>
            <div class="img-overlay"></div>
        </div>
        <section class="book-now" id="kontakt">
            <h3> Kontaktiere mich </h3>
            <p class="desktop"> Das Leben ist zu kurz, dass wir uns nicht begegnen!
                <br> Ich freu mich sehr, Dich kennenzulernen. </p>
            <p class="mobile"> Das Leben ist zu kurz, dass wir uns nicht begegnen!
                Ich freu mich sehr, Dich kennenzulernen. </p>
            <div class="button-wrapper">
                <a href="mailto:lisa@millerlisa.com"> 
                    <button @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                    Schreibe mir eine E-Mail </button>
                </a>
                <a href="tel:+491635138890"> 
                    <button @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                    Ruf mich an </button>
                </a>
            </div>
            <p> Oder benutze einfach das Formular hier. </p>
            <div v-if="sended" class="button-wrapper form-sended form-container">
                <p class="danke"> Dankeschön. </p>
                <p class="error"> Deine Nachricht wurde erfolgreich zugestellt und ich antworte Dir so schnell wie möglich. </p>
                <button @mouseenter="cursorEnter" @mouseleave="cursorOut" @click="sendAgain"> 
                    Erneut eine Nachricht senden</button>
            </div>
            <div v-if="error" class="button-wrapper form-sended form-container form-error">
                <p class="error"> Ups. <br> Leider ging etwas schief. </p>
                <button @mouseenter="cursorEnter" @mouseleave="cursorOut" @click="sendAgain"> 
                    Erneut versuchen</button>
            </div>
            <form>
                <div v-if="!sended && !error" class="form-container">
                    <label>Dein Name <b>*</b></label>
                    <input @mouseenter="cursorEnterInput" @mouseleave="cursorOutInput"
                        type="text" 
                        v-model="name"
                        name="name"
                    >
                    <label>Deine Firma</label>
                    <input @mouseenter="cursorEnterInput" @mouseleave="cursorOutInput"
                        type="text" 
                        v-model="company"
                        name="name"
                    >
                    <label>Deine E-Mail <b>*</b> </label>
                    <input @mouseenter="cursorEnterInput" @mouseleave="cursorOutInput"
                        id="mailformular"
                        type="email" 
                        v-model="email"
                        name="email"
                        :onchange="mailValidation()"
                        >
                    <label>Deine Nachricht an mich <b>*</b></label>
                    <textarea @mouseenter="cursorEnterInput" @mouseleave="cursorOutInput"
                        name="message"
                        v-model="message"
                        cols="30" rows="5">
                    </textarea>
                </div>
                <div v-if="name == '' || email == '' || message == ''" class="send-mail"> 
                    <img src="../assets/Icons/icon-send.png" alt="Sending Icon"> 
                    <div> Senden </div>
                </div>
                <div v-else-if="isSending" class="send-mail"> 
                    <img src="../assets/Icons/icon-send.png" alt="Sending Icon"> 
                    <div> wird gesendet ... </div>
                </div>
                <div v-else @mouseenter="cursorEnter" @mouseleave="cursorOut" class="send-mail send-mail-ok"> 
                    <img src="../assets/Icons/icon-send.png" alt="Sending Icon"> 
                    <div @click="sendEmail"> Senden </div>
                </div>
            </form>
        </section>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import MainMenu from '@/components/Menu.vue'
import axios from "axios";


export default {
    name: 'Home',
    components: {
        MainMenu,
        Footer
    },
    data() {
        return {
            name: '',
            company: '',
            email: '',
            message: '',
            sended: false,
            error: false,
            isSending: false,
        }
    },
    methods: {
        cursorEnter() {
            this.$store.commit("setMouseOverLink", true);
        },
        cursorOut(){
            this.$store.commit("setMouseOverLink", false);
        },
        cursorEnterInput() {
            document.getElementById("cursor").style.opacity = 0;
        },
        cursorOutInput(){
            document.getElementById("cursor").style.opacity = 1;
        },
        mailValidation() {
            if (document.getElementById("mailformular")) {
                if (this.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                    document.getElementById("mailformular").style.borderBottomColor = '#7AA3C8';
                } else {
                    document.getElementById("mailformular").style.borderBottomColor = '#CA085E';
                }
            }
        },
        sendEmail() {
            this.isSending = true;
            let data = {
                    "user_id": 'user_RjwBCA41NBR3K5T0diyP7',
                    "service_id": 'lisa_business',
                    "template_id": 'neue_nachricht',
                    "template_params": {
                        'name': this.name,
                        'company': this.company,
                        'email': this.email,
                        'message': this.message
                    }
                };
            axios.post('https://api.emailjs.com/api/v1.0/email/send', 
                data
            ).then(response => {
                if (response.status == 200) {
                    this.isSending = false;
                    this.sended = true;
                    this.company = '';
                    this.name = '';
                    this.email = '';
                    this.message = '';
                    this.cursorOut();
                } else {
                    this.isSending = false;
                    this.error = true;
                    this.cursorOut();
                }
            });
            this.cursorOut();
            
        },
        sendAgain() {
            this.sended = false;
            this.error = false;
            this.cursorOut();
        }
    }
}
</script>

<style lang="scss" scoped>
$pink: #CA085E;
$lightblue: #C4D1DB;
$midblue: #7AA3C8;
$skyblue: #4476AE;
$darkblue: #031940;
$blackblue: #010819;
$grey: #131313;

.hero {
    width: calc(100vw - 40px);
    height: 75vh;
    margin: 0 20px 20px 20px;
    overflow: hidden;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 50% 0%;
    }
}

.welcome-wrapper {
    position: relative;
    .headline {
        text-align: center;
        position: absolute;
        top: -8.5rem;
        left: 0;
        width: 100%;
        text-align: center;
        text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    }
}

.more-cite {
    margin: 20px;
    background-color: #f7e8e6;
    display: flex;
    justify-content: center;
    align-items: center;
    .letterwriting-mobile {
        display: none;
    }
    h2 {
        text-align: center;
        font-size: 2.3rem;
        font-weight: 100;
        color: #6b042d;
        margin: 6rem 0;
        max-width: 800px;
    }
}

.content {
    margin: 6rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
        max-width: 600px;
    }
    h4 {
        max-width: 600px;
        margin: 2rem 0 0 0;
        text-align: left;
        color: $skyblue;
        text-transform: uppercase;
    }
    .mountains-link {
        max-width: 600px;
        font-weight: 500;
        display: flex;
        align-items: center;
        img {
            width: 40px;
            margin-left: 20px;        
        }
    }
}

.strength {
    margin: 6rem 0;
    text-align: center;
    .phone {
        width: 250px;
        background-size: cover;
        background-position: center;
        border-color: white;
        .screen-top { 
            background-color: #ffffff96;
            .speakers, .camera { 
                background-color: white;
            }
        }
        .topline {
            background-color: white;
        }
        .headline {
            color: $skyblue;
        }
    }
    .design {
        background-image: url("../assets/Bilder/More/rise-confident-hero.jpg");
    }
    .fotografie {
        background-image: url("../assets/Bilder/More/LisaHomeDasBinIch.jpg");
    }
    .coding {
        background-image: url("../assets/Bilder/More/LisaRCCover.jpg");

    }
    .content {
        margin-bottom: 0;
    }
}

.hero-cite {
    margin-top: 20rem;
    position: relative;
    background-image: url("./../assets/Bilder/More/DSC00378-1.jpg");
    background-size: cover;
    background-position: center;

    .hero-cite-desktop {
        display: flex;
    }
    .hero-cite-mobile {
        display: none;
    }
    div {
        text-align: center;
        height: 100%;
        position: absolute;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20vw;
        h2 {
            margin: 0;
        }
    }
    .img-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 0;
        background-color: $grey;
        opacity: 0.8;
    }
}

.book-now {
    margin: 0 20px 20px 20px;
    padding: 6rem 0;
    background-color: $lightblue;
    color: $darkblue;
    text-align: center;
    .mobile {
        display: none;
    }
    p {
        max-width: 520px;
        margin: 0 auto;
        font-weight: 300;
    }
    h3 {
        color: white;
        margin-bottom: 2rem;
    }
    .button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 3rem auto;
        button {
            color: white;
            background-color: $darkblue;
            margin: 0 1rem;
        }
        button:hover {
            background-color: $skyblue;
        }
    }

    .form-sended {
        box-sizing: border-box;
        background-color: #4476AE1f;
        border-radius: 15px;
        border: 2px solid $midblue;
        display: flex;
        width: 600px;
        flex-direction: column;
        padding: 4rem 30px;
        margin: 1rem auto;
        .danke {
            font-family: "Bacalisties";
            font-size: 2rem;
            margin-bottom: 2rem;
        }
        .error {
            margin-bottom: 2rem;
        }
    }
    .form-error {
        background-color: #ca085f38;
        border: 2px solid $pink;
    }
    form {
        width: 600px;
        text-align: left;
        margin: 1rem auto;
        .form-container {
            box-sizing: border-box;
            background-color: #4476AE1f;
            border-radius: 15px;
            border: 2px solid $midblue;
            display: flex;
            flex-direction: column;
            padding: 20px 30px;
            width: 600px;
            label {
                margin-top: 10px;
                cursor: none;
            }
            input, textarea {
                margin-bottom: 10px;
                background-color: transparent;
                border: none;
                border-bottom: 2px solid $midblue;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            }
            input {
                height: 1.1rem;
            }
            input:focus, textarea:focus {
                outline: none;
            }
        }   
    }
    .send-mail {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 600px;
        margin: 0 auto;
        margin-top: 1.5rem;
        opacity: 0.4;
        img {
            width: 25px;
            margin-right: 10px;
        }
        div {
            font-family: 'Raleway', sans-serif;
            background-color: transparent;
            border: none;
            cursor: none;
            color: $blackblue;
            font-size: 1.2rem;
            font-weight: 400;
            margin-right: 10px;
        }
    }
    .send-mail-ok {
        opacity: 1;
    }
}

@media screen and (max-width: 1300px) {
    .welcome-wrapper {
        .headline {
            text-align: left;
            top: -6.5rem;
        }
        h2 {
            margin-bottom: 3.5rem;
            margin-left: 20px;
        }
    }  
    .hero {
        width: 100%;
        height: 50vh;
        margin: 0;
    }
    .more-cite {
        margin: 20px 0 0 0;
        h2 {
            font-size: 2rem;
            margin: 5rem 0;
        }
    }
    .content {
        margin: 4rem 0;
    }
    .strength {
        .phone {
            width: 210px;
        }
    }
}

@media screen and (max-width: 700px) { 
    .welcome-wrapper {
        .headline {
            top: -3.5rem;
        }
    } 
    .more-cite {
        .letterwriting-desktop {
            display: none;
        }
        .letterwriting-mobile {
            text-align: left;
            display: flex;
        }
        h2 {
            font-size: 1.5rem;
            margin: 2rem 20px;
        }
    }
    .content {
        margin: 2rem 0 2rem 0;
        align-items: start;
        p, h4, .mountains-link {
            text-align: left;
            margin-left: 20px;
            margin-right: 20px;
        }
        .mountains-link {
            flex-direction: column;
            align-items: start;
            img {
                margin: 10px 0 0 0;
            }
        }
    }
    .book-now {
        margin: 5rem 0 0 0;
        padding: 5rem 20px 2rem 20px;
        .desktop {
            display: none;
        }
        .mobile {
            display: block;
        }
        .button-wrapper {
            margin: 3rem auto;
            flex-direction: column;
            button {
                margin: 10px;
            }
        }
        .form-sended {
            width: calc(100% - 20px);
            margin: 1rem auto;
        }
        form {
            width: calc(100% - 20px);
            .form-container {
                width: 100%;
            }

        }
        .send-mail {
            width: 100%;
            div {
                font-size: 0.9rem;
            }
        }
    }
}

</style>