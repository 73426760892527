<template>
    <div id="app">
        <div id="cursor"><span id="cursor-span"></span></div>
        <div id="nav">
            <div @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                <div class="menu-wrapper" @click="toggleMenu()">
                    <div id="hamburger-menu"></div>	  
                </div>
                <router-link id="author" class="link" to="/home"> LISA MILLER </router-link>
            </div>
            <div @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                <a id="nav-contact" class="link" @click="updateShortcutContact()"> KONTAKT </a>
            </div>
        </div>        
        <Contact />

        <router-view/>
        
        <!--
        <div class="more-links" v-if="!$store.state.isStartActive && !mobile">
            <div class="more-link" @mouseenter="cursorEnter" @mouseleave="cursorOut"
                v-if="$router.currentRoute.name != 'more'">
                <router-link to="/mehr">
                    <div> MEHR </div>
                    <img src="../src/assets/Icons/arrow_down.png" alt="Pfeil down">
                </router-link>
            </div>
            <div class="more-link" @mouseenter="cursorEnter" @mouseleave="cursorOut"
                v-if="$router.currentRoute.name == 'more'">
                <a @click="$router.go(-1)">
                    <div> ZURÜCK </div>
                </a>
            </div>
            <div class="projects-link" @mouseenter="cursorEnter" @mouseleave="cursorOut"
                v-if="$router.currentRoute.name != 'projects'">
                <router-link to="/projekte">
                    <div> PROJEKTE </div>
                    <img src="../src/assets/Icons/arrow_down.png" alt="Pfeil down">
                </router-link>
            </div>
            <div class="projects-link" @mouseenter="cursorEnter" @mouseleave="cursorOut"
                v-if="$router.currentRoute.name == 'projects'">
                <a @click="$router.go(-1)">
                    <div> ZURÜCK </div>
                </a>
            </div>
        </div>
        -->
    </div>
</template>

<script>
import Contact from '@/components/Contact.vue'

export default {
    name: "App",
    components: {
        Contact
    },
    metaInfo() {
        return { 
            title: "Lisa Miller Design",
            meta: [
                { name: 'description', content:  'Als Dein Websiteexperte unterstütze ich Dich in der Umsetzung Deiner neuen Website. Ich helfe Dir zu mehr Sichtbarkeit und Authentizität im Web.'},
                { property: 'og:title', content: "Lisa Miller Design - Mit mir zur neuen authentischen Website."},
                { property: 'og:site_name', content: 'Lisa Miller Design'},
                { property: 'og:type', content: 'website'},    
                { name: 'robots', content: 'index,follow'} 
            ]
        }
    },
    data() {
        return {
            mobile: false,
        }
    },
    created () {
        window.onmousemove = this.logMouseMove;
        document.onscroll = this.logMouseMove;

        let height = "innerHeight" in window  ? window.innerHeight : document.documentElement.offsetHeight; 
		let width = "innerWidth" in window  ? window.innerWidth : document.documentElement.offsetWidth; 
		
        if (height/width >=1 ) {
			this.mobile = true;
		}
    },
    methods: {
        logMouseMove(e) {
            e = e || window.event;	
            let posx = e.clientX;
            let posy = e.clientY;
            let cursor = document.getElementById("cursor");
            cursor.style.left = posx - 70 + "px";
            cursor.style.top = posy - 70 + "px";
            if (this.$store.state.mouseOverLink){
                cursor.classList.add("over-link");
            } else {
                cursor.classList.remove("over-link");
            }
        },
        cursorEnter() {
            this.$store.commit("setMouseOverLink", true);
        },
        cursorOut(){
            this.$store.commit("setMouseOverLink", false);
        },
        toggleMenu() {
            document.getElementById("hamburger-menu").classList.toggle('animate');
            let menuState = document.getElementById("hamburger-menu").classList.contains('animate');
            this.$store.commit("toggleMenu", menuState);
        },
        toHome() {
            return "/" + this.$store.state.homeState;
        },
        updateShortcutContact() {
            this.$store.commit("toggleContact");
        }
    }
}
</script>

<style lang="scss">
$pink: #CA085E;
$lightblue: #C4D1DB;
$midblue: #7AA3C8;
$skyblue: #4476AE;
$darkblue: #031940;
$blackblue: #010819;
$grey: #131313;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@font-face {
  font-family: "Bacalisties";
  src: url('./assets/fonts/Bacalisties.ttf');
}

body::-webkit-scrollbar {
  width: 5px;
}
 
body::-webkit-scrollbar-track {
  box-shadow: none;
  -ms-box-shadow: none;
  -webkit-box-shadow: none;
}
 
body::-webkit-scrollbar-thumb  {
  background-color: $midblue;
  outline: 2px solid transparent;
  border-radius: 10px;
}

.more-links {
    position: fixed;
    z-index: 999;
    margin: 0 40px;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    a {
        display: flex;
        height: 2.3rem;
    }
    div {  
        font-size: 3rem;
        font-weight: 800;
        line-height: 75%;
    }
    img {
        margin-left: 0.6rem;
        height: 100%;
    }
}
.more-link {
    opacity: 0.15;
}
.projects-link {
    opacity: 0.15;
    margin: 0 30px;
}
.projects-link:hover, .more-link:hover {
    opacity: 1;
    text-shadow: 0px 0px 5px white;
    transition: all 0.3s ease;
}


/*
+ Custom cursor */
@keyframes pulse {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1, 1);
        -moz-transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1, 1);
        -ms-transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1, 1);
        -o-transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1, 1);
        transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1, 1); }
    50% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1.1, 1.1);
        -moz-transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1.1, 1.1);
        -ms-transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1.1, 1.1);
        -o-transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1.1, 1.1);
        transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(180deg) scale(1.1, 1.1); }
    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1, 1);
        -moz-transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1, 1);
        -ms-transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1, 1);
        -o-transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1, 1);
        transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(360deg) scale(1, 1); 
    } 
}
#cursor {
    display: block;
    z-index: 10000;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 144px;
    height: 144px;
    border-radius: 50%;
    user-select: none;
    pointer-events: none;
}
#cursor span {
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(0.25, 0.25);
    -moz-transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(0.25, 0.25);
    -ms-transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(0.25, 0.25);
    -o-transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(0.25, 0.25);
    transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(0.25, 0.25);
    -moz-transition: 250ms ease-in-out;
    -o-transition: 250ms ease-in-out;
    -webkit-transition: 250ms ease-in-out;
    transition: 250ms ease-in-out; 
}
#cursor span:before, #cursor span:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    background: url(assets/cursor3.png) center no-repeat;
    background-size: 100%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1, 1);
    -moz-transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1, 1);
    -ms-transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1, 1);
    -o-transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1, 1);
    transform: translateX(-50%) translateY(-50%) translateZ(0px) rotate(0deg) scale(1, 1);
    -moz-transition: 250ms ease-in-out;
    -o-transition: 250ms ease-in-out;
    -webkit-transition: 250ms ease-in-out;
    transition: 250ms ease-in-out; 
}
#cursor span:after {
    opacity: 0;
    background-image: url(assets/cursorLisa.png); 
}
#cursor.over-link span {
    -webkit-transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(0.5, 0.5);
    -moz-transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(0.5, 0.5);
    -ms-transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(0.5, 0.5);
    -o-transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(0.5, 0.5);
    transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(0.5, 0.5); 
    filter: brightness(1.5);
}
#cursor.over-link span:before {
    opacity: 0; 
}
#cursor.over-link span:after {
    opacity: 1;
    animation: pulse 4000ms infinite; 
}

.menu {
    background-color: transparent;
}

$bar-width: 25px;
$bar-height: 2px;
$bar-spacing: 7px;

.menu-wrapper {
	position: relative;
    top: 0;
    left: 40px;
    right: 0;
    bottom: 0;
    margin: auto;
    width: $bar-width;
	height: $bar-height + $bar-spacing*2;
}

#hamburger-menu,
#hamburger-menu:after,
#hamburger-menu:before {
    width: $bar-width;
	height: $bar-height;
}

#hamburger-menu {
	position: relative;
	transform: translateY($bar-spacing);
	background: rgba(255, 255, 255, 1);
	transition: all 0ms 300ms;
  
  &.animate {
    background: rgba(255, 255, 255, 0); 
  }
}

#hamburger-menu:before {
	content: "";
	position: absolute;
	left: 0;
	bottom: $bar-spacing;
	background: rgba(255, 255, 255, 1);
	transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

#hamburger-menu:after {
	content: "";
	position: absolute;
	left: 0;
	top: $bar-spacing;
	background: rgba(255, 255, 255, 1);
	transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

#hamburger-menu.animate:after {
	top: 0;
	transform: rotate(45deg);
	transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}

#hamburger-menu.animate:before {
	bottom: 0;
	transform: rotate(-45deg);
	transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}

$pink: #CA085E;
$lightblue: #C4D1DB;
$midblue: #7AA3C8;
$skyblue: #4476AE;
$darkblue: #031940;
$blackblue: #010819;
$grey: #131313;

body {
    cursor: none !important;
    background-color: $grey;
    margin: 0;
}

a {
    color: white;
    //color: #ffa7f3;
    text-decoration: none !important;
}
a:hover, button:hover {
    cursor: unset;
}
h1 {
    margin: 0;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    font-weight: 200;
    line-height: 150%;
}
h2 {
    margin: 0;
    margin-bottom: 3rem;
    font-size: 3rem;
    font-weight: 900;
    line-height: 150%;
}
h3 {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1.6rem;
    font-weight: 900;
    line-height: 180%;
}
h4 {
    margin: 0; 
    font-size: 1.2rem;   
    line-height: 180%;
    font-weight: 800;
}
h5 {
    margin: 0; 
    font-size: 1rem;   
    line-height: 180%;
    font-weight: 200;
    letter-spacing: 0.5px;
}
li {
    text-align: left;
}
p, div, span {
    font-size: 1rem;
    line-height: 180%;
    font-weight: 200;
    letter-spacing: 0.5px;
}
.letterwriting {
    font-family: 'Bacalisties';
}
.skyblue {
    color: $skyblue;
}
#app {
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
}

button {
    color: $darkblue;
    background-color: #7AA3C8;
    border: none;
    border-radius: 10px;
    font-size: 0.9rem;
    font-weight: 100;
    padding: 13px 17px;
    transition: 0.5s ease;
}
button:hover {
    font-weight: 300;
    background-color: $lightblue;
    box-shadow: 0 0 15px -5px white;
}


#nav {
    z-index: 9997;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin: 30px 0;
    .link {
        margin: 0 40px;
        font-size: 1rem;
        font-weight: 400;
        position: relative;
        top: 2px;
    }
    #author {
        margin-left: 55px;
    }
}
#nav > div {
    position: relative;
    display: flex;
    flex-direction: row;
}

.phones {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    .phone-content {
        display: flex;
        flex-wrap: wrap;
        .phone {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #4476AE1f;
            border-radius: 15px;
            border: 2px solid $midblue;
            box-shadow: 0 0 20px -8px black;
            margin: 20px;
            width: 210px;
            padding: 0px 35px 15px 35px;
            text-align: left;
            color: white;
            .screen-top {
                width: 130px;
                height: 17px;
                margin: 0 auto 3rem auto;
                background-color: #4476AE40;
                border-radius: 0 0 10px 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                .speakers {
                    width: 50px;
                    height: 3px;
                    border-radius: 2px;
                    margin-right: 7px;
                    background-color: $midblue;
                }
                .camera {
                    width: 5px;
                    height: 5px;
                    border-radius: 2px;
                    background-color: $midblue;
                }
            }
            .topline {
                width: 80px;
                height: 3px;
                margin: 4rem auto 0 auto;
                background-color: $midblue;
                border-radius: 2px;
            }
            .headline {
                font-weight: 600;
                color: $skyblue;
                text-transform: uppercase;
            }
            .content {
                margin-top: 1rem;
            }
        }
    }
}

@media (max-width: 1300px) {
    body::-webkit-scrollbar {
        width: 0px;
    }
    
    p, div, span {
        font-size: 1.1rem;
    }
    .phone-content {
        flex-direction: column;
    }
}

@media (max-width: 700px) {
    #cursor {
        display: none;
    }
    h1 {
        font-size: 1.8rem;
        line-height: 150%;
    }
    h2 {
        font-size: 2rem;
        line-height: 150%;
    }
    h3 {
        font-size: 1.6rem;
        line-height: 180%;
    }
    h4 {
        font-size: 1.2rem;   
        line-height: 180%;
    }
    h5 {
        font-size: 1rem;   
        line-height: 180%;
    }
    p, div, span {
        font-size: 15px;
    }

    .phones {
        margin-top: 2rem;
    }

    $bar-width-mobile: 15px;
    $bar-height-mobile: 1px;
    $bar-spacing-mobile: 5px;
    .menu-wrapper {
        left: 20px;
        width: $bar-width-mobile;
        height: $bar-height-mobile + $bar-spacing-mobile*2;
    }
    #hamburger-menu,
    #hamburger-menu:after,
    #hamburger-menu:before {
        width: $bar-width-mobile;
        height: $bar-height-mobile;
    }

    #hamburger-menu {
        transform: translateY($bar-spacing-mobile);
    }

    #hamburger-menu:before {
        bottom: $bar-spacing-mobile;
    }

    #hamburger-menu:after {
        top: $bar-spacing-mobile;
    }
    #nav {
        margin: 0;
        padding: 15px 0;
        background-color: #131313f0;
        box-shadow: 0 0 20px 0 black;
        .link {
            margin: 0 20px;
            font-size: 0.9rem;
            top: 0px;
        }
        #author {
            margin-left: 30px;
        }
    }
    #nav > div {
        position: relative;
        display: flex;
        flex-direction: row;
    }
}
</style>
