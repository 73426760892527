<template>
  <div class="work-overview">
    <h2>Projekte</h2>
    <div id="best-work" @mouseenter="cursorEnter" @mouseleave="cursorOut">
      <div class="work-project" @click="goTo('weather')">
        <section class="work-project-hover left">
          <div class="work-project-name">Wetter App</div>
          <div class="work-project-desc">
            Wettervorhersage fürs Tablet mit Design.
            Basierend auf realen Daten bekommst du das Wetter für die nächsten 14 Tage.
            Wie wird das Wetter in deiner Stadt?
          </div>
          <img class="work-project-icon" :src="require(`@/assets/Icons/code.png`)" />
        </section>
        <div class="work-project-img-wrapper">
          <img class="work-project-img" :src="require(`@/assets/Projekte/mockup_weather-preview.jpg`)" />
        </div>
      </div>

      <div class="work-project">
        <section class="work-project-hover left">
          <div class="work-project-name">IoT-Leitstelle</div>
          <div class="work-project-desc">
            Eine IoT-Plattform für Versogungsunternehmen als redundante Fernüberwachung.
            Meine Bachelorarbeit in Kooperation mit Smart City Solutions GmbH.
          </div>
          <img class="work-project-icon" :src="require(`@/assets/Icons/code.png`)" />
        </section>
        <div class="work-project-img-wrapper">
          <img class="work-project-img" :src="require(`@/assets/Projekte/Finale-Screens-web.png`)" />
        </div>
      </div>

      <div class="work-project">
        <section class="work-project-hover left">
          <div class="work-project-name">Thiself</div>
          <div class="work-project-desc">
            Die progessive Vue-WebApp lässt dich deine Lieblingsorte nicht mehr vergessen.
            Eine App für jedes Smartphone, auch für deins.
          </div>
          <img class="work-project-icon" :src="require(`@/assets/Icons/code.png`)" />
        </section>
        <div class="work-project-img-wrapper">
          <img class="work-project-img" :src="require(`@/assets/Projekte/FlyingiPhone_Thiself.jpg`)" />
        </div>
      </div>

      <div class="work-project">
        <section class="work-project-hover left">
          <div class="work-project-name">Website E&M</div>
          <div class="work-project-desc">
            Redesign & Umsetzung der Website für E&M Wasseranlagenbau GmbH mit Wordpress.
            Schau mal vorbei!
          </div>
          <img class="work-project-icon" :src="require(`@/assets/Icons/code.png`)" />
        </section>
        <div class="work-project-img-wrapper">
          <img class="work-project-img" :src="require(`@/assets/Projekte/website_leittechnik.jpg`)" />
        </div>
      </div>

      <div class="work-project">
        <section class="work-project-hover left">
          <div class="work-project-name">Zirkl</div>
          <div class="work-project-desc">
            Du machst gerne Sport?
            Zirkl übernimmt die Organisation für dein Intervalltraining.
            Oder dein Zirkeltrainig.
          </div>
          <img class="work-project-icon" :src="require(`@/assets/Icons/code.png`)" />
        </section>
        <div class="work-project-img-wrapper">
          <img class="work-project-img" :src="require(`@/assets/Projekte/Zirkl_running.jpg`)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AllProjects",
  methods: {
    cursorEnter() {
      this.$store.commit("setMouseOverLink", true);
    },
    cursorOut() {
      this.$store.commit("setMouseOverLink", false);
    }
  },
};
</script>

<style lang="scss" scoped>
.router-link {
  cursor: none;
}
h2 {
  margin-bottom: 60px;
}
.button {
  color: #fff;
  padding: 10px 20px;
  margin: 30px;
  border: 1px solid black;
  background-color: #00000033;
  box-shadow: 0 0 0 0 #000;
  transition: 0.5s all ease;
}
.button:hover {
  background-color: #00000055;
  box-shadow: 0 0 15px -5px #000;
}
.work-overview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 7.5rem;
}
#best-work {
  position: relative;
  width: 80vw;
  height: 60vh;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  box-shadow: 0 0 20px 0px #000;
}
.work-project {
  position: relative;
  width: 100%;
  height: inherit;
  color: #fff;
  overflow: hidden;
}
.work-project:hover > section {
  transform: translate(0, 0);
}
.work-project-hover {
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  background-color: #222222ee;
  transition: all 0.3s ease-out;
}
.left {
  transform: translate(-100%, 0);
}
.right {
  transform: translate(100%, 0);
}
.work-project-hover-left {
  position: absolute;
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  background-color: #222222ee;
  transform: translate(-100%, 0);
  transition: all 0.3s ease-out;
}
.work-project-name {
  position: absolute;
  top: 45%;
  z-index: 3;
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: 300;
  font-size: 1.5rem;
}
.work-project-desc {
  position: absolute;
  top: 48%;
  z-index: 2;
  padding: 15%;
  font-size: 0.8rem;
  font-weight: 300;
  text-align: center;
  color: #a5a5a5;
}
.work-project-icon {
  position: absolute;
  top: 25%;
  width: 25%;
  z-index: 1;
}
.work-project-img-wrapper {
  position: absolute;
  width: inherit;
  height: inherit;
  overflow: hidden;
  display: flex;
  justify-content: center;
  z-index: -5;
}
.work-project-img-wrapper:hover {
  opacity: 0.6;
}
.work-project-img {
  height: 100%;
}
</style>
