<template>
    <div class="home">
        <MainMenu />

        <router-view></router-view>

        <Footer />
        
    </div>
</template>

<script>
//import SideMenu from '@/components/SideMenu.vue'
import MainMenu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'Home',
    components: {
        //SideMenu,
        MainMenu,
        Footer
    },
    mounted() {
        this.$store.commit("setMouseOverLink", false);
    },
    methods: {
        cursorEnter() {
            this.$store.commit("setMouseOverLink", true);
        },
        cursorOut(){
            this.$store.commit("setMouseOverLink", false);
        }
    }
}
</script>

<style lang="scss" scoped>
$pink: #CA085E;
$lightblue: #C4D1DB;
$midblue: #7AA3C8;
$skyblue: #4476AE;
$darkblue: #031940;
$blackblue: #010819;
$grey: #131313;


</style>