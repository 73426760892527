<template>
    <div id="start">
        <MainMenu />
        <div id="colorcircles-wrapper"></div>
        <div id="start-titel">
            <h1 id="welcome-title">lisa miller</h1>
            <p id="sub-title" class="desktop"> WEBENTWICKLERIN & WEBDESIGNERIN
            <br> <span> KLICKE HIER... </span>  </p>
            <div class="horizontal-divider"></div>
            
        </div> 
        <div id="dive-in">
            <div class="circles" id="inner-circle"></div>
            <div class="circles" id="strokes"></div>
            <div class="circles" id="outer-circle"></div>
            <router-link to="/home">
                <div id="button" 
                    @click="cursorOut()"
                    @mouseenter="cursorEnterButton" 
                    @mouseleave="cursorLeaveButton">
                    
                    <p id="welcome-button"> KLICKE <span> HIER </span>
                    <br>& TAUCH' EIN </p>
                </div>
                <div class="mobile">
                <router-link to="/home">
                    <button href="/home"> erfahre mehr </button>
                </router-link> 
            </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import MainMenu from '@/components/Menu.vue'

export default {
    name: "Start",
    components: {
        MainMenu
    }, 
    data() {
		return {
			mobile: false,
        }
    },
    mounted() {
        let height = "innerHeight" in window  ? window.innerHeight : document.documentElement.offsetHeight; 
		let width = "innerWidth" in window  ? window.innerWidth : document.documentElement.offsetWidth; 
		if (height/width >=1 ) {
			this.mobile = true;
		}
        this.mouseMove();
        this.$store.commit("setStart", true);
    },
    beforeDestroy() {
        this.$store.commit("setStart", false);
    },
    methods: {
        mouseMove() {
            if (!this.mobile) {
                let mX, mY, distance;
                window.addEventListener('mousemove', e => {   
                    mX = e.pageX;
                    mY = e.pageY;
                    distance = this.calculateDistance(mX, mY);
                    const colorcirclesWrapper = document.getElementById("colorcircles-wrapper");
                    const outerCircle = document.getElementById("outer-circle");
                    const strokes = document.getElementById("strokes");
                    const innerCircle = document.getElementById("inner-circle");
                    if (colorcirclesWrapper != null) {
                        colorcirclesWrapper.style.opacity = 1 - distance * 0.0015;
                        outerCircle.style.transform = `scale(${1 + distance * 0.005})`;
                        strokes.style.transform = `scale(${1 + distance * 0.02})`;
                        innerCircle.style.transform = `scale(${1 + distance * 0.005})`;
                    }
                });
            }
        },
        calculateDistance(mouseX, mouseY) {
            const cX = window.innerWidth/2;
            const cY = window.innerHeight/2;
            return Math.floor(Math.sqrt(Math.pow(mouseX - cX, 2) + Math.pow(mouseY - cY, 2)));
        },
        cursorEnterButton() {
            this.cursorEnter();
            const button = document.getElementById("button");
            const start = document.getElementById("start-titel");
            button.style.opacity = 1;
            start.style.opacity = 0;
        }, 
        cursorLeaveButton() {
            this.cursorOut();
            const button = document.getElementById("button");
            const start = document.getElementById("start-titel");
            button.style.opacity = 0;
            start.style.opacity = 1;
        },
        cursorEnter() {
            this.$store.commit("setMouseOverLink", true);
        },
        cursorOut(){
            this.$store.commit("setMouseOverLink", false);
        }
    }
}
</script>

<style lang="scss" scoped>
$pink: #CA085E;
$lightblue: #C4D1DB;
$midblue: #7AA3C8;
$skyblue: #4476AE;
$darkblue: #031940;
$blackblue: #010819;
$grey: #131313;

#start {
    width: 100vw;
    height: 100vh;
}

#colorcircles-wrapper {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: -2;
    background-image: url("../assets/Bilder/Andere/Farbkreise3.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: auto 90vh;
    transform: translate(-40px, -20px);
    opacity: 0.2;
}

#start-titel {
    position: fixed;
    height: 100vh;
    width: 100vw;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: -1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .mobile {
        display: none;
    }

    #welcome-title {
        font-family: "Bacalisties";
        font-size: 5rem;
        line-height: 1rem;
        margin-bottom: 10px;
    }

    #sub-title {
        text-align: center;
        font-size: 1.5rem;
        margin-top: 1rem;
        margin-bottom: 0;
        line-height: 2rem;
        font-weight: 800;
        letter-spacing: 10px;
        span {
            font-weight: 300;
            font-size: 1.2rem;
            letter-spacing: 5px;
        }
    }
}

.horizontal-divider {
    width: 193px;
    border-bottom: 1px solid $pink;
    margin: 0px auto;
}

#dive-in {
    position: fixed;
    z-index: 10;
    height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .mobile {
        display: none;
    }

    #button {
        text-align: center;
        padding: 8rem;
        border: none;
        border-radius: 50%;
        color: white;
        opacity: 0;
        transition: all 0.5s ease;
        p {
            font-size: 1.3rem;
            line-height: 2rem;
            font-weight: 600;
            span {
                font-size: 1.3rem;
                line-height: 2rem;  
                font-weight: 900;
            }
        }
    }

    .circles {
        position: fixed; 
        z-index: -1;
    }
    #inner-circle {
        width: 50vh;
        height: 50vh;
        border-radius: 50%;
        border: 1px solid white;
        transform: scale(5);
    }
    #strokes {
        width: 60vh;
        height: 60vh;
        background-image: url("../assets/Bilder/Andere/strokes.png");
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: auto 100%;
        transform: scale(4);
    }
    #outer-circle {
        width: 130vh;
        height: 130vh;
        border-radius: 50%;
        border: 1px solid white;
        transform: scale(3);
    }
}

@media (max-width: 700px) {
    #colorcircles-wrapper {
        background-position: 54% 150%; /* Center the image */
        background-size: auto 140vh;
        transform: translate(0, 0);
        opacity: 1;
    }
    #start-titel {
        justify-content: start;
        top: 260px;
        #welcome-title {
            margin-top: 1rem;
            text-align: center;
            font-size: 3rem;
            line-height: 3rem;
        }
        #sub-title {
            text-align: center;
            font-size: 1rem;
            margin-top: 0rem;
            padding: 0 3rem;
            letter-spacing: 5px;
            span {
                display: none;
            }
        }
    }

    #dive-in {
        #button {
            display: none;
        }
        .mobile {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            top: 50px;
            width: 100vw;
            z-index: 10;
            button {
                background-color: $midblue;
                color: white;
                margin-top: 3rem;
                box-shadow: 0 0 10px -5px white;
                animation: pulse 5s infinite, grow 10s infinite;
            }
        }
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 15px -3px rgba(255, 255, 255, 1);
    }
    50% {
        box-shadow: 0 0 40px -5px rgba(255, 255, 255, 0.2);
    }
    100% {
        box-shadow: 0 0 15px -3px rgba(255, 255, 255, 1);
    }
}

@keyframes grow {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(1)
    }
}
</style>