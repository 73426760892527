<template>
    <div>
        <MainMenu />
        <section class="projects">
            <h3> Hier ist Platz für einige Projekte</h3>
            <p> Willst du eins? <br> Dann melde dich bei mir! </p>
            <router-link to="/mehr#kontakt">
                <button @mouseenter="cursorEnter" @mouseleave="cursorOut" @click="cursorOut">
                    Let's go!
                </button> 
            </router-link>
        </section>
        
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import MainMenu from '@/components/Menu.vue'

export default {
    name: 'Projects',
    components: {
        MainMenu,
        Footer
    },
    methods: {
        cursorEnter() {
            this.$store.commit("setMouseOverLink", true);
        },
        cursorOut(){
            this.$store.commit("setMouseOverLink", false);
        }
    }
}
</script>

<style lang="scss" scoped>
.projects {
    width: 100vw;
    height: calc(100vh - 84px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    button {
        margin-top: 2rem;
    }
}
</style>