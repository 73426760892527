import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        menuOpen: false,
        isStartActive: false,
        homeState: "website",
        contactOpen: false,
        transitionName: "fade",
        mouseOverLink: false,
        thisProject: "thiself",
        wordCloud: 0
    },
    mutations: {
        toggleMenu(state, payload) {
            state.menuOpen = payload;
            let height = "innerHeight" in window  ? window.innerHeight : document.documentElement.offsetHeight; 
            let width = "innerWidth" in window  ? window.innerWidth : document.documentElement.offsetWidth; 
            if (payload) {
                if (height/width >=1 ) { //mobile
                    document.getElementById("main-menu").style.height = "70vh";
                } else {
                    document.getElementById("main-menu").style.height = "100vh";
                }
            } else {
                document.getElementById("main-menu").style.height = "0vh";
            }
        },
        setStart(state, payload) {
            state.isStartActive = payload;
        },
        setHomeState(state, payload) {
            state.homeState = payload;
            if (payload == "website") {
                document.getElementById("go-to-website").style.fontWeight = "800";
                document.getElementById("go-to-shootings").style.fontWeight = "300";
            } else if (payload == "shootings") {
                document.getElementById("go-to-website").style.fontWeight = "300";
                document.getElementById("go-to-shootings").style.fontWeight = "800";
            }
        },
        toggleContact(state) {
            state.contactOpen = !state.contactOpen;
            if (state.contactOpen) {
                document.getElementById("tel-button").style.transform = "translateX(0px)";
                document.getElementById("mail-button").style.transform = "translateX(0px)";
            } else {
                document.getElementById("tel-button").style.transform = "translateX(200px)";
                document.getElementById("mail-button").style.transform = "translateX(200px)";
            }
        },
        setState(state, payload) {
            state.state = payload;
        },
        setThisProject(state, payload) {
            state.thisProject = payload;
        },
        setTransition(state, payload) {
            state.transitionName = payload;
        },
        setMouseOverLink(state, payload) {
            state.mouseOverLink = payload;
        },
        setWordCloud(state, payload) {
            state.wordCloud = payload;
        }
    },
    actions: {

    }
})
