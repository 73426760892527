<template>
    <div>
        <div class="hero">
            <img src="./../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="Paar Shooting Hero">
        </div>
        <section class="welcome">
            <h2> Wundervoll und einzigartig. Das bist du. </h2>
            <div class="mouse-section">
                <img src="../assets/Icons/mouse.png" alt="scroll down" />
            </div>
        </section>
        <section class="start">
            <span> Was machen Fotos mit Dir? <br>
                Für mich sind Fotos eingefangene Momente für die Ewigkeit. <br>
                Fotos auf denen Du bist, erzählen Deine Geschichte, 
                Deine Gegenwart vielleicht auch Deine Vergangenheit oder Deine Zukunft. </span>
            <h4> Sie erzählen von Deiner Lebendigkeit, 
                Deiner Herzlichkeit und Deiner Einzigartigkeit. </h4>
            <h5> Fotos erinnern, begeistern, inspirieren. </h5>
        </section>
        <section class="start-fotos">
            <div id="start-fotos-scroll"> 
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
            </div>
        </section>
        <section class="description-text">
            <p> Die große Leidenschaft der Fotografie begleitet mich schon mehrere Jahre. <br>
                Jeder Moment ist weder für die Vergangenheit gedacht noch für die Zukunft, 
                Jeder Moment ist genau jetzt um gelebt zu werden. 
                Mit Bildern schaffen wir etwas großartiges, denn unser Gehirn 
                kann sich zwar unglaublich viele Momente abspeichern, 
                aber oftmals fehlt uns der Schlüssel um zu diesen Erinnerungen zu kommen. 
                Bilder sind solche Schlüssel zu Erinnerungen. 
                Bei mir ist das sehr ausgeprägt. Ein Bild kann eine ganze Reihe von 
                Erinnerungen hervorrufen. Deine Gefühle, die du in dem Moment hattest. 
                Die Gerüche und die Menschen, die um dich herum waren. 
                Das Glück, das Du gespürt hast. </p>
            <p> Du bekommst bei mir ein besonderes Erlebnis. 
                Egal ob privat oder beruflich, Du bist bei mir richtig. 
                Ich sorge dafür, dass Du dich wohlfühlst und dass Deine Bilder Dich begeistern!</p>
        </section>
        <section class="services-wrapper">
            <h3> Meine Leistungen </h3>
            <div class="services"> 
                <div class="cite"> Lass Dich 
                    <br> von 
                    <br> Dir selbst
                    <br> verzaubern 
                    <br> und 
                    <br> begeistere
                    <br> andere 
                    <br> von Dir. </div>
                <div class="vertical-divider" > </div>
                <div class="service-content">
                    <div class="icon">
                        <img src="../assets/Icons/design.svg" alt="Design">
                    </div>
                    <h4> Single Shooting </h4>
                    <h4> Bewerbungsshooting </h4>
                    <span class="mb-2"> Persönliches Kennenlernen
                    <br> Gemeinsames Shooting 30 - 180 Min 
                    <br> in der Region Ravensburg, 
                        deutschen Bodenseeregion oder Region Ulm
                    <br> Wahlweise in der Stadt oder in der Natur </span>
                    <div class="icon mt">
                        <img src="../assets/Icons/website.svg" alt="Design">
                    </div>
                    <h4> Paar Shooting </h4>
                    <span class="mb-2"> Persönliches Kennenlernen
                    <br> Gemeinsames Shooting 30 - 180 Min 
                    <br> in der Region Ravensburg, 
                        deutschen Bodenseeregion oder Region Ulm
                    <br> Wahlweise in der Stadt oder in der Natur </span>
                    <div class="icon mt">
                        <img src="../assets/Icons/pflege.svg" alt="Design">
                    </div>
                    <h4> Business Shooting </h4>
                    <h4> Mitarbeiter Shooting </h4>
                    <span class="mb-2"> Persönliches Kennenlernen
                    <br> Gemeinsames Shooting 30 - 180 Min 
                    <br> in der Region Ravensburg, 
                        deutschen Bodenseeregion oder Region Ulm
                    <br> In den Örtlichkeiten Deines Businesses </span>
                </div>
            </div>
        </section>
        <section class="reference-fotos">
            <h3> Single Shootings </h3>
            <div class="fotos-scroll" id="single-1-fotos-scroll"> 
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
            </div>
            <div class="fotos-scroll" id="single-2-fotos-scroll"> 
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
            </div>
            <div class="fotos-scroll" id="single-3-fotos-scroll"> 
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
            </div>
            <h3> Paar Shootings </h3>
            <div class="fotos-scroll" id="couple-1-fotos-scroll"> 
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
            </div>
            <h3> Business Shootings </h3>
            <div class="fotos-scroll" id="business-1-fotos-scroll"> 
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
                <img src="../assets/Bilder/Shootings/IMG_9378-44.jpg" alt="">
            </div>
        </section>
        <section class="references-wrapper">
            <h3> Meine Kundenstimmen </h3>
            <div class="references">
                <div class="name-section"> 
                    <img @mouseenter="cursorEnter" @mouseleave="cursorOut"
                        class="arrow-left" src="../assets/Icons/arrow_right.png" alt="Pfeil links">
                    <h2> Andreas Kessler </h2>
                    <img @mouseenter="cursorEnter" @mouseleave="cursorOut"
                        class="arrow-right" src="../assets/Icons/arrow_right.png" alt="Pfeil rechts">
                </div>
                <div class="reference-type">
                    <span class="workplace"> Geschäftsführer E&M Wasseranlagenbau </span> 
                    <div class="reference-circle"> </div> 
                    <span class="type"> Relauch Website </span>
                </div>
                <div class="refence-cite">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, 
                    sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
                    sed diam voluptua. At vero eos et accusam .
                </div>
            </div>
        </section>
        <section class="book-now">
            <p> Du brauchst Fotos für dein Business? 
            <br> Oder willst Du ganz besondere Bilder von dir oder von Euch als Paar? </p>
            <h5> Sag hallo! </h5>
            <p class="first-call"> Lass uns in einem kostenlosen und unverbindlichen Erstgespräch herausfinden,
            ob ich die richtige Fotografin für Dich bin. </p>
            <button @mouseenter="cursorEnter" @mouseleave="cursorOut"> 
                Jetzt kostenfrei anfragen </button>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Fotos',
    mounted() {
        window.addEventListener("scroll", this.scrollUp);
        this.scrollUp();
    },
    methods: {
        cursorEnter() {
            this.$store.commit("setMouseOverLink", true);
        },
        cursorOut() {
            this.$store.commit("setMouseOverLink", false);
        },
        scrollUp() {
            const scrollTop =
                window.pageYOffset ||
                (document.documentElement || document.body.parentNode || document.body)
                .scrollTop;
                const startWrapper = document.getElementById("start-fotos-scroll");
                if (startWrapper != undefined) {
                    let translate = (-400 + scrollTop*0.1);
                    startWrapper.style.transform = `translateX(${translate}px)`;
                }
                const singleWrapper1 = document.getElementById("single-1-fotos-scroll");
                if (singleWrapper1 != undefined) {
                    let translate = (100 + scrollTop*-0.1);
                    singleWrapper1.style.transform = `translateX(${translate}px)`;
                }
                const singleWrapper2 = document.getElementById("single-2-fotos-scroll");
                if (singleWrapper2 != undefined) {
                    let translate = (-400 + scrollTop*0.1);
                    singleWrapper2.style.transform = `translateX(${translate}px)`;
                }
                const singleWrapper3 = document.getElementById("single-3-fotos-scroll");
                if (singleWrapper3 != undefined) {
                    let translate = (-100 + scrollTop*-0.1);
                    singleWrapper3.style.transform = `translateX(${translate}px)`;
                }
                const coupleWrapper1 = document.getElementById("couple-1-fotos-scroll");
                if (coupleWrapper1 != undefined) {
                    let translate = (-600 + scrollTop*0.1);
                    coupleWrapper1.style.transform = `translateX(${translate}px)`;
                }
                const businesWrapper1 = document.getElementById("business-1-fotos-scroll");
                if (businesWrapper1 != undefined) {
                    let translate = (0 + scrollTop*-0.1);
                    businesWrapper1.style.transform = `translateX(${translate}px)`;
                }
        }
    }

}
</script>

<style lang="scss" scoped>
$pink: #CA085E;
$lightblue: #C4D1DB;
$midblue: #7AA3C8;
$skyblue: #4476AE;
$darkblue: #031940;
$blackblue: #010819;
$grey: #131313;

.hero {
    width: calc(100vw - 40px);
    height: 75vh;
    margin: 20px;
    overflow: hidden;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }
}
.welcome {
    text-align: center;
    position: relative;
    top: -2.7rem;
    h2 {
        margin: 0 30px;
        margin-bottom: 3rem;
    }
}
.start {
    text-align: center;
    position: relative;
    top: 8rem;
    max-width: 650px;
    margin: 0 auto;
    margin-bottom: 200px;
    h5 {
        margin-top: 5rem;
    }
}

.start-fotos {
    width: 100%;
    height: 400px;
    overflow: hidden;
    #start-fotos-scroll {
        margin: 0 10px;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        img {
            height: 100%;
            margin: 0 10px;
        }
    }
}

.description-text {
    margin: 20px;
    padding: 3rem 0;
    text-align: center;
    background-color: $lightblue;
    color: #010819;
    p {
        font-size: 1rem;
        font-weight: 400;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
    }
}

.services-wrapper {
    margin: 0 auto;
    margin-top: 10rem;
    max-width: 800px;
    text-align: center;
    .services {
        margin-top: 4rem;
        display: flex;
        .cite {
            text-align: right;
            width: 400px;
            font-size: 4rem;
            font-weight: 800;
            line-height: 100%;
            opacity: 0.15;
            color: $midblue;
        }
        .vertical-divider {
            margin: 0 50px;
            border-right: 1px solid white;
        }
        .service-content {
            width: 400px;
            text-align: left;
            .icon {
                width: 50px;
                filter: invert(100%);
                margin-bottom: 0.2rem;
            }
            .mt {
                margin-top: 2.5rem;
            }
        }
        .mb-2 {
            margin-bottom: 2rem;
        }
    }
}

.reference-fotos {
    margin-top: 5rem;
    text-align: center;
    width: 100%;
    overflow: hidden;
    h3 {
        margin-top: 7rem;
    }
    .fotos-scroll {
        margin: 20px 10px;
        height: 400px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        transition: all 0.2s ease-out;
        img {
            height: 100%;
            margin: 0 10px;
        }
    }
}

.references-wrapper {
    margin: 0 auto;
    margin-top: 10rem;
    width: 700px;
    text-align: center;
    .references {
        margin-top: 5rem;
        .name-section {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 2rem;
            h2 {
                margin: 0;
            }
            img {
                width: 50px;
                margin: 0 50px;
            }
            .arrow-left {
                transform: scaleX(-1);
            }
        }
        .reference-type {
            margin-bottom: 0.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            span {
                font-weight: 800 !important;
                text-transform: uppercase;
            }
            .workplace {
                color: $lightblue;
            }
            .type {
                color: $midblue;
            }
            .reference-circle {
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: white;
                margin: 0 10px;
            }
        }
    }
}

.book-now {
    margin: 20px;
    margin-top: 10rem;
    padding: 4rem;
    background-color: $lightblue;
    color: $blackblue;
    text-align: center;
    p {
        width: 600px;
        font-weight: 400;
        margin: 0 auto;
    }
    p:nth-child(1) {
        color: $skyblue;
        font-weight: 800;
    } 
    h5 {
        color: $skyblue;
        margin-top: 2rem;
        font-weight: 600;
    }
    .first-call {
        margin: 5rem auto 0rem auto;
    }
    button {
        margin: 2rem auto 0 auto;
        color: white;
        background-color: $blackblue;
        box-shadow: 0 0 20px -8px black;
        border: none;
        border-radius: 10px;
        font-size: 1rem;
        font-weight: 100px;
        padding: 15px 20px;
        transition: all 0.5s ease;
    }
    button:hover {
        box-shadow: 0 0 20px -3px black;
    }
}


footer {
    padding: 20px;
    display: flex;
    justify-content: center;
    .link {
        margin: 0 10px;
    }
}


.mouse-section {
    position: relative;
    top: 120px;
    left: 49.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15px;
    transform: translate(0, 40px);
    img {
        width: 100%;
        filter: invert(1);
        transform: rotate(180deg) translate(0, 100px);
        opacity: 0.6;
        animation: mouseAni 3s infinite;
    }
}

@keyframes mouseAni {
    0% {
        -webkit-transform: rotate(180deg) translate(0, 100px);
        -moz-transform: rotate(180deg) translate(0, 100px);
        -ms-transform: rotate(180deg) translate(0, 100px);
        -o-transform: rotate(180deg) translate(0, 100px);
        transform: rotate(180deg) translate(0, 100px);
        opacity: 0;
    }
    5% {
        -webkit-transform: rotate(180deg) translate(0, 100px);
        -moz-transform: rotate(180deg) translate(0, 100px);
        -ms-transform: rotate(180deg) translate(0, 100px);
        -o-transform: rotate(180deg) translate(0, 100px);
        transform: rotate(180deg) translate(0, 100px);
        opacity: 0;
    }
    85% {
        opacity: 0.6;
    }
    95% {
        -webkit-transform: rotate(180deg) translate(0, 80px);
        -moz-transform: rotate(180deg) translate(0, 80px);
        -ms-transform: rotate(180deg) translate(0, 80px);
        -o-transform: rotate(180deg) translate(0, 80px);
        transform: rotate(180deg) translate(0, 80px);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotate(180deg) translate(0, 100px);
        -moz-transform: rotate(180deg) translate(0, 100px);
        -ms-transform: rotate(180deg) translate(0, 100px);
        -o-transform: rotate(180deg) translate(0, 100px);
        transform: rotate(180deg) translate(0, 100px);
        opacity: 0;
    }
}
</style>