import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Icon }  from 'leaflet'
import 'leaflet/dist/leaflet.css'
import VueMeta from 'vue-meta'



delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

Vue.config.productionTip = false

Vue.use(VueMeta)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
