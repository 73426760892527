<template>
    <div class="footer">
        <footer>
            <div class="block">
                    <p class="headline"> Weitere Links </p>
                    <div class="text"> 
                        <div @mouseenter="cursorEnter" @mouseleave="cursorOut" @click="cursorOut">
                            <router-link class="link" to="/home#kontakt"> Kontaktformular </router-link> 
                        </div> 
                        <div @mouseenter="cursorEnter" @mouseleave="cursorOut" @click="cursorOut">
                            <router-link class="link" to="/home"> Nach oben </router-link> 
                        </div> 
                        <div @mouseenter="cursorEnter" @mouseleave="cursorOut" @click="cursorOut">
                            <router-link class="link" to="/berge" target="_blank"> Meine Bergliebe App </router-link>
                        </div> 
                    </div>
            </div>
            <div class="block">
                <p class="headline"> Bachelorarbeit </p>
                <div class="text"> 
                    <div @mouseenter="cursorEnter" @mouseleave="cursorOut" @click="cursorOut">
                        <a href="2020_Lisa_Miller_BA_IoT-Leitstelle.pdf" class="link" target="_blank"> Bachelorarbeit <br> IoT Leitstelle </a>
                    </div>
                </div>
            </div>
            <div class="block">
                <p class="headline"> Kontakt </p>
                <div class="text"> 
                    <div @mouseenter="cursorEnter" @mouseleave="cursorOut" @click="cursorOut">
                        <a href="mailto:lisa@millerlisa.com" class="link"> lisa@millerlisa.com </a>
                    </div>
                    <div @mouseenter="cursorEnter" @mouseleave="cursorOut" @click="cursorOut">
                        <a href="tel:+491635138890" class="link"> +49 1635138890 </a>
                    </div>
                    <div @mouseenter="cursorEnter" @mouseleave="cursorOut" @click="cursorOut">
                        <a href="millerlisa.com" class="link"> www.millerlisa.com </a>
                    </div>
                </div>
            </div>
            <div class="block">
                <p class="headline"> Anschrift </p>
                <div class="text"> 
                    <span>Lisa Miller</span>
                    <span>Moosbruggerstraße 3</span>
                    <span>88250 Weinagrten</span>
                </div>
            </div>
            <div class="block policy">
                <p class="headline"> Rechtliches </p>
                <div class="text"> 
                    <div @mouseenter="cursorEnter" @mouseleave="cursorOut" @click="cursorOut">
                        <router-link id="impressum" class="link" to="/impressum"> Impressum </router-link> 
                    </div> 
                    <div @mouseenter="cursorEnter" @mouseleave="cursorOut" @click="cursorOut">
                        <router-link id="privacy-police" class="link" to="/datenschutz"> Datenschutz </router-link>
                    </div> 
                </div>
            </div>
        </footer>
        <div class="copyright">
            &copy; 2021 Lisa Miller Design
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer",
    methods: {
        cursorEnter() {
            this.$store.commit("setMouseOverLink", true);
        },
        cursorOut(){
            this.$store.commit("setMouseOverLink", false);
        }
    }

}
</script>

<style lang="scss" scoped>
$pink: #CA085E;
$lightblue: #C4D1DB;
$midblue: #7AA3C8;
$skyblue: #4476AE;
$darkblue: #031940;
$blackblue: #010819;
$grey: #131313;

.footer {
    margin: 20px 20px 0 20px;
    background-color: $blackblue;
    footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        padding: 2rem 3rem;
        margin: 0 auto;
        max-width: 1500px;
        .block {
            margin: 3rem 5px;
            min-width: 220px;
            .headline {
                font-weight: 600;
            }
            .text {
                display: flex;
                flex-direction: column;
            }
        }
        .policy {
            min-width: 100px;
        }
    }
    .copyright {
        text-align: center;
        font-weight: 400;
        color: $skyblue;
        padding-bottom: 2rem;
    }
}

.link {
    transition: all 0.2s ease-in;
}
.link:hover {
    font-weight: 600;
    color: $skyblue;
}

@media screen and (max-width: 1100px) {
    .footer {
        footer {
            justify-content: start;
            max-width: 700px;
            padding: 3rem 2rem;
            .block {
                margin: 1rem 5px;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .footer {
        text-align: left;
        margin: 0;
        footer {
            padding: 20px;
            flex-direction: column;
            .block {
                margin: 1rem 5px;
            }
        }
    }
}
</style>