import Vue from 'vue'
import VueRouter from 'vue-router'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Kontakt from '../views/Kontakt.vue'
import Start from '../views/Start.vue'
import Impressum from '../views/Impressum.vue'
import Policy from '../views/Policy.vue'
import More from '../views/More.vue'
import Projects from '../views/Projects.vue'
import Mountains from '../views/Mountains.vue'
import Fotos from '../components/Fotos.vue'
import Website from '../components/Website.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

export default new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            redirect: '/start'
        },
        { 
            path: '/start',
            name: 'start',
            component: Start
        },
        {
            path: '/',
            component: Home,
            children: [
                {
                    path: 'home',
                    name: 'website',
                    component: Website
                },
                {
                    path: 'shootings',
                    name: 'shootings',
                    component: Fotos
                }
            ]
        },
        { 
            path: '/kontakt',
            name: 'contact',
            component: Kontakt
        },
        { 
            path: '/mehr',
            name: 'more',
            component: More
        },
        { 
            path: '/projekte',
            name: 'projects',
            component: Projects
        },
        { 
            path: '/berge',
            name: 'mountains',
            component: Mountains
        },
        { 
            path: '/impressum',
            name: 'impressum',
            component: Impressum
        },
        { 
            path: '/datenschutz',
            name: 'policy',
            component: Policy
        },
        {
          path: '*',
          redirect: '/404'
        },
        { 
            path: '/404',
            name: '404',
            component: NotFound
        },
    ],
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash
            };
        }
        if (savedPosition) {
            return savedPosition;
        }
        return {x: 0, y: 0 };
    }
})